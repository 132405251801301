import React from "react"
import LazyImage from "../../components/LazyImage"

import "./index.scss"


export const IndustryHeader = ({header, image }) => {

    return (
        <div className="industry-template-header">
            <LazyImage alt={`${header} at newsoft`} src={image} className="industry-template-header-image" />
            <div className="industry-template-header-content">
                <p className="t-s-12">our expertise in</p>
                <h1 className="t-h-24">{header}</h1>
            </div>
        </div>
    )
}
