export const OUR_TEAM_FIRST_RAW = [
    "https://newsoft-ns.com/media/gallery/first-row/gallery1.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery2.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery3.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery4.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery5.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery6.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery7.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery8.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery9.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery10.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery11.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery12.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery13.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery14.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery15.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery16.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery17.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery18.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery19.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery20.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery21.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery22.webp",
    "https://newsoft-ns.com/media/gallery/first-row/gallery23.webp",
]

export const OUR_TEAM_SECOND_RAW = [
    "https://newsoft-ns.com/media/gallery/second-row/gallery1.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery2.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery3.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery4.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery5.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery6.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery7.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery8.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery9.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery10.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery11.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery12.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery13.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery14.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery15.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery16.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery17.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery18.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery19.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery20.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery21.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery22.webp",
    "https://newsoft-ns.com/media/gallery/second-row/gallery23.webp",
]
