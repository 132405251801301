import React from "react"
import "./index.scss"
import LazyImage from "../../components/LazyImage"
import AppLink from "../../components/AppLink"
import { useMediaQuery } from "react-responsive"
import { MONITOR_WIDTH } from "../../constants/common"

const CaseClientFeedback = ({text, image, name, desc, company, linkedin}) => {
    const isMonitor = useMediaQuery({ minWidth: MONITOR_WIDTH })

    return (
        <div className="case-template-feedback">
            <h3 className="t-h-m-22">Client feedback</h3>
            <div className="case-template-feedback-content">
                <div className="case-template-feedback-content-client">
                    <div className="case-template-feedback-content-client-image">
                        <LazyImage
                            alt={name}
                            src={image}
                        />
                    </div>
                    <div className="case-template-feedback-content-client-info">
                        <h4 className="t-n-m-20">{name}</h4>
                        <hr/>
                        <div className="case-template-feedback-content-client-info-desc">
                            <p className="t-s-14">{desc} at  
                                <strong className="case-template-feedback-content-client-info-desc-company"> {company}</strong>
                            </p>
                        </div>
                        <AppLink className="t-s-14 clickable-link-white-background" href={linkedin}>
                                LinkedIn
                        </AppLink>
                    </div>
                </div>
                {isMonitor && <hr/>}
                <div className="case-template-feedback-content-text">
                    {text.map((el, i) => (
                        <p className="t-s-14" key={i}>{el}</p>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CaseClientFeedback
