import React, { useEffect, useState } from "react"
import "./index.scss"
import { Link } from "react-router-dom"
import {
    CONTACT_US_EMAIL_TEMPLATE,
    NAVIGATION_LINKS,
    REACT_APP_ENV,
    REACT_APP_ENV_OPTIONS,
    SEND_EMAIL_API,
} from "../../constants/common"
import loadingAnimationData from "../../lotties/loading.json"
import successAnimationData from "../../lotties/success.json"
import Lottie from "react-lottie"


const SendEmail = () => {
    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState(CONTACT_US_EMAIL_TEMPLATE)
    const [agreement, setAgreement] = useState(false)
    const [messageColor, setMessageColor] = useState("#757579")
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)

    const updateMessageWithFullName = (message, fullName) => {
        const [beforeSignature, signature] = message.split("Best regards,")
        if (signature) {
            const updatedSignature = `Best regards,\n${fullName || "Full Name"}`
            return `${beforeSignature.trim()}\n\n${updatedSignature}`
        }
        return message
    }

    useEffect(() => {
        setMessage((prevMessage) => updateMessageWithFullName(prevMessage, fullName))
    }, [fullName])

    const handleMessageChange = (e) => {
        setMessage(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        // POST-запит
        fetch(SEND_EMAIL_API, {
            method: "POST",
            mode: "no-cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: fullName,
                email: email,
                message: message,
                test: REACT_APP_ENV === REACT_APP_ENV_OPTIONS.PRODUCTION ? false : true,
            }),
        })
            .then(() => {
                setTimeout(() => {
                    setLoading(false)
                    setFormSubmitted(true)
                }, 400)
            })
            .catch((error) => {
                console.error("There has been a problem with your fetch operation:", error)
                setLoading(false)
            })
    }

    const loadingAnimationOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    const successAnimationOptions = {
        loop: false,
        autoplay: true,
        animationData: successAnimationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    return (
        <form className="send-email-form" onSubmit={handleSubmit}>
            {!formSubmitted ? <div className="send-email">
                <div className="send-email-field">
                    <label className="send-email-field-label">Full Name</label>
                    <input
                        className="send-email-field-input"
                        placeholder="Full Name"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                    />
                </div>

                <div className="send-email-field">
                    <label className="send-email-field-label">Email</label>
                    <input
                        className="send-email-field-input"
                        placeholder="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className="send-email-field">
                    <label className="send-email-field-label">Message</label>
                    <textarea
                        className="send-email-field-textarea"
                        value={message}
                        onClick={() => setMessageColor("#27272D")}
                        onChange={handleMessageChange}
                        style={{ color: messageColor }}
                    ></textarea>
                </div>

                <div className="send-email-agreement">
                    <input
                        type="checkbox"
                        checked={agreement}
                        onChange={(e) => setAgreement(e.target.checked)}
                    />
                    <label className="t-s-12">
            I have read and agree to the{" "}
                        <Link to={NAVIGATION_LINKS.TERMS_AND_CONDITIONS}>
              Terms and Conditions
                        </Link>
            .
                    </label>
                </div>

                <button disabled={!agreement || !email || !fullName || loading} type="submit" className={loading ? "loading" : ""}>
                    {loading ? <Lottie options={loadingAnimationOptions}
                        width={"60px"} height={"22px"}
                    /> : <span>Send</span>  }
                </button>
            </div> : 
                <div className="send-email-success">
                    <div>
                        <Lottie options={successAnimationOptions}
                            width={"110px"} height={"110px"} />
                    </div>

                    <div className="send-email-success-info">
                        <p className="t-s-20">Thank you for submitting form !</p>
                        <span className="t-b3">We will contact you as soon as possible</span>
                    </div>
                </div>
            }
        </form>
    )
}

export default SendEmail
