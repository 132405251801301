import React from "react"
import BaseIcon from "../BaseIcon"

const PacksmithIcon = ({ ...props }) => (
    <BaseIcon {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_564_20916)">
            <path d="M14.8788 3.75801L22.6228 9.38187V17.4639C22.6228 18.2746 21.9625 18.9314 21.1433 18.9314H6.10363C5.29007 18.9314 4.62407 18.2765 4.62407 17.4695V9.38563L12.3681 3.75801L8.46108 0.916992L0.605444 6.62342C0.221365 6.89739 0 7.34212 0 7.80937V23.5194H27.2488V7.80562C27.2488 7.33837 27.0217 6.89739 26.6433 6.62342L18.7877 0.916992L14.8788 3.75801Z" fill="white"/>
            <path d="M34.1689 0.916992H43.9375C48.465 0.916992 51.6891 3.6229 51.6891 7.92946C51.6891 12.236 48.3099 14.9419 43.9999 14.9419H38.3882V23.5232H34.1709V0.916992H34.1689ZM43.875 11.1889C46.0773 11.1889 47.4718 9.866 47.4718 7.92946C47.4718 5.99292 46.1378 4.66998 43.9052 4.66998H38.3863V11.1908H43.875V11.1889Z" fill="white"/>
            <path d="M69.5541 16.3548L71.6467 23.5212H67.399L65.8647 17.7079L65.5866 21.7367C64.4078 23.0896 62.7031 23.981 60.2227 23.981C55.4169 23.981 52.0681 20.3518 52.0681 15.5236C52.0681 10.6954 55.3867 6.94238 60.1924 6.94238C62.5177 6.94238 64.2849 7.83372 65.526 9.18667V7.52598H69.5579V16.353L69.5541 16.3548ZM60.9643 20.3537C63.5375 20.3537 65.6773 18.4772 65.6773 15.5255C65.6773 12.5738 63.5375 10.5734 60.9643 10.5734C58.3912 10.5734 56.2514 12.5719 56.2514 15.5255C56.2514 18.4791 58.2663 20.3537 60.9643 20.3537Z" fill="white"/>
            <path d="M88.1338 11.4966L84.5673 13.557C84.1946 11.7424 82.5203 10.6053 80.5355 10.6053C77.9 10.6053 76.04 12.3898 76.04 15.4636C76.04 18.5372 77.8696 20.3218 80.5355 20.3218C82.4578 20.3218 84.102 19.0608 84.5673 17.462L88.0412 19.4605C86.9551 22.0125 84.1341 23.981 80.5374 23.981C75.3589 23.981 71.855 20.5057 71.855 15.4617C71.855 10.4177 75.4838 6.94238 80.5374 6.94238C84.1039 6.94238 87.0799 8.91083 88.1338 11.4947V11.4966Z" fill="white"/>
            <path d="M93.4335 0.916992V15.9965L95.0587 11.8288L99.4804 7.52789H104.876L97.9308 14.2007L105.032 23.5194H100.101L95.1079 16.9066L93.4335 18.5054V23.5176H89.4016V0.916992H93.4335Z" fill="white"/>
            <path d="M119.075 11.5266L115.293 12.9415C115.168 11.0649 113.866 10.0498 111.788 10.0498C110.114 10.0498 109.121 10.7872 109.121 11.8944C109.121 14.8781 119.26 12.448 119.26 18.8749C119.26 22.3803 116.097 23.9809 112.222 23.9809C108.129 23.9809 105.152 22.3203 104.563 19.2746L108.377 17.644C108.532 19.9821 110.207 20.8415 112.222 20.8415C113.711 20.8415 114.982 20.258 114.982 19.1189C114.982 15.9814 104.998 18.5654 104.998 12.1384C104.998 9.12468 108.129 6.94043 111.788 6.94043C115.758 6.94043 118.457 8.72498 119.075 11.5229V11.5266Z" fill="white"/>
            <path d="M128.247 10.6056C126.199 10.6056 125.176 12.2364 125.084 14.7565V23.5216H121.052V7.53012H125.084V11.9287L126.045 8.23755C126.541 7.77594 127.594 6.94653 129.518 6.94653C132.372 6.94653 134.417 8.76111 134.915 11.7447L135.783 8.23944C136.341 7.65585 137.488 6.94841 139.225 6.94841C142.791 6.94841 144.931 9.1927 144.931 13.2835V23.5253H140.899V13.9309C140.899 11.7166 139.908 10.6095 138.109 10.6095C136.031 10.6095 135.007 12.0863 135.007 14.7002V23.5272H130.975V13.9627C130.975 12.272 130.262 10.6114 128.247 10.6114V10.6056Z" fill="white"/>
            <path d="M146.933 0.916992H151.121V4.91581H146.933V0.916992ZM151.026 7.52976V23.5213H146.994V7.52976H151.026Z" fill="white"/>
            <path d="M159.366 1.43994V7.52917H163.894V10.9125H159.366V18.0469C159.366 19.8615 160.017 20.3232 161.569 20.3232C162.126 20.3232 163.118 20.1993 163.708 20.0773V23.5525C163.15 23.7684 161.538 23.9222 161.01 23.9222C157.321 23.9222 155.336 22.3854 155.336 18.6023V10.9144H152.421V7.53106H155.336V4.45547L159.368 1.44182L159.366 1.43994Z" fill="white"/>
            <path d="M181.012 14.1407V23.5213H176.98V14.7261C176.98 11.8663 176.019 10.6354 173.757 10.6354C171.493 10.6354 170.067 12.2661 170.067 15.0639V23.5213H166.035V0.916992H170.067V12.1121L170.965 8.57498C171.864 7.59169 173.073 6.94431 175.058 6.94431C178.934 6.94431 181.012 9.46632 181.012 14.1407Z" fill="white"/>
            <path d="M184.851 23.7257C183.695 23.7257 182.755 22.7931 182.755 21.6465C182.755 20.5001 183.695 19.5674 184.851 19.5674C186.007 19.5674 186.948 20.5001 186.948 21.6465C186.948 22.7931 186.007 23.7257 184.851 23.7257ZM184.851 20.0178C183.945 20.0178 183.209 20.7477 183.209 21.6465C183.209 22.5454 183.945 23.2754 184.851 23.2754C185.758 23.2754 186.493 22.5454 186.493 21.6465C186.493 20.7477 185.758 20.0178 184.851 20.0178Z" fill="white"/>
            <path d="M185.296 21.8273C185.567 21.7635 185.713 21.5703 185.713 21.2812C185.713 20.9247 185.46 20.707 185.076 20.707H183.989V22.5835H184.384V21.8517H184.84L185.359 22.5835H185.82L185.298 21.8273H185.296ZM184.384 21.5027V21.0598H185.059C185.224 21.0598 185.317 21.1555 185.317 21.2831C185.317 21.4107 185.226 21.5027 185.059 21.5027H184.384Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_564_20916">
                <rect width="188" height="25" fill="white"/>
            </clipPath>
        </defs>
    </BaseIcon>
)

export default PacksmithIcon
