export const OUR_ARTICLES = [
    {
        header: "Compose Multiplatform VS. Flutter",
        text: "Hi! I'm Artem, Senior Mobile Developer at Newsoft with 8 years of mobile development experience working with Android and Flutter. Today we'll have a look at Compose Multiplatform for cross-platform development and compare it to another famous framework from Google — Flutter...",
        time: "14 min read",
        link: "https://medium.com/newsoft-official/compose-multiplatform-vs-flutter-771673612c61"
    },
    {
        header: "Flutter White Labeling: BuildVariants VS. Dependencies",
        text: "Hello! My name is Anton and I'm Senior Flutter Developer at Newsoft with 10 years of mobile development under the belt. Today, we're going to talk about a few app labeling methods and compare them using Flutter. Before we start, I invite you to get to know the project's code....",
        time: "17 min read",
        link: "https://medium.com/newsoft-official/flutter-white-labeling-buildvariants-vs-dependencies-d7758983affb"
    }, 
    {
        header: "Testing Shopify Applications",
        text: "Among the range of constantly developing e-commerce platforms, Shopify stands out as a titan that has revolutionized the way companies and online shopping interact. From its launch to its current status as a famous figure in the e-commerce industry, Shopify's journey is marked by innovation...",
        time: "11 min read",
        link: "https://medium.com/newsoft-official/testing-shopify-applications-fd61f47209a6"
    },
    {
        header: "Challenges and wins in HR processes",
        text: "First, the coronavirus, and then the beginning of a full-scale invasion, forced representatives of different types of business to face unprecedented challenges. While the coronavirus challenges became a problem for the whole world and the solutions applied in the USA were also relevant for the Ukrainian market, the challenges associated with the...",
        time: "5 min read",
        link: "https://medium.com/newsoft-official/challenges-and-wins-in-hr-processes-in-newsoft-2023-53d87054c5b1"
    },
    {
        header: "Unlocking the potential of Microservices",
        text: "The evolution of software development never misses a chance to surprise me. Developers all over the world, just like me, have witnessed how it shaped the way we design, build, and maintain complex applications. From monolithic systems to modern microservices, software architecture has transformed significantly.",
        time: "5 min read",
        link: "https://medium.com/newsoft-official/unlocking-the-potential-of-microservices-86e00b200d73"
    },
]