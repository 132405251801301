import React from "react"
import LazyImage from "../../components/LazyImage"
import AppLink from "../../components/AppLink"

import "./index.scss"


export const PeopleCard = ({ name, text, image, link }) => {
    return (
        <div className="people-card">
            <div className="people-card-image">
                <LazyImage alt={`${name} - ${text}`} src={image} />
                <h4 className="t-s1-m">{name}</h4>
                <hr />
                <h5 className="t-s-16">{text}</h5>
                <AppLink className="people-card-image-linkedin clickable-link-white-background" href={link}>LinkedIn</AppLink>
            </div>
        </div>
    )
}
