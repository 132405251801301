import { NAVIGATION_LINKS } from "../../constants/common"

export const ORBIT_HEADER = {
    image: "https://newsoft-ns.com/media/cases/orbit.webp",
    imageAlt: "Orbit & Newsoft",
    header: "Orbit",
    desc: "IT Staff Augmentation for an American recruitment agency",
    link: NAVIGATION_LINKS.ORBIT,
    industry: {label: "Staffing/Recruiting", value: "staffing_recruiting"},
    duration: "6 months",
    location: "USA",
    service: [
        {label: "IT Staff Augmentation", value: "it_staff_augmentation"}
    ],
    color: "#9C3F3F"
}

export const ORBIT_PROJECT_OVERVIEW = [
    "Orbit is a recruitment platform designed for freelance recruiters. The system enables companies to post job vacancies which are then accessed by recruiters. The project started with a four-page document outlining the client's product vision. The Newsoft team worked on the project from scratch and created more features than initially planned. The team developed the database, back end, and front end, and designed the project. ",
    "Team: Project Manager, QA Engineer, Python Developer"
]

export const ORBIT_CHALLENGES = [
    "Develop a bounty and user-friendly application system that meets the requirements of both recruiters and companies",
    "Implement appropriate security measures to safeguard sensitive candidate and employer information",
    "Integrate a Slack bot seamlessly with various companies' Slack teams and notifications",
    "Establish effective communication channels for recruiters, employers, and candidates throughout the application process",
    "Design an efficient application review process that takes into account the needs of all parties involved",
    "Optimize queries and application logic to achieve optimal performance"
]

export const ORBIT_SOLUTIONS = [
    "Conducted regular code reviews and testing throughout the development process to identify and address bugs and issues",
    "Implemented industry best practices and coding standards to ensure a consistent and maintainable codebase",
    "Collaborated with other team members, including the Designer and Project Manager, to ensure that technical solutions aligned with project goals and user needs",
    "Documented the development process and system architecture thoroughly to facilitate future maintenance and scalability",
    "Monitored the system continuously for performance and usability issues, and any problems that arose were promptly addressed"
]

export const ORBIT_CLIENT_FEEDBACK = {
    image: "https://newsoft-ns.com/media/clients/jacob_muchow.webp",
    name: "Jacob Muchow",
    desc: "Founder",
    company: "Orbit",
    linkedin: "https://www.linkedin.com/in/jacobmuchow/",
    text: [
        "“Newsoft delivered an MVP ahead of schedule, meeting expectations. Everything was delivered on time and within budget. Newsoft was very flexible with our budget needs and MVP goals. They were also flexible allowing us to extend a week beyond the original contract to add in a few new things.”"
    ],
}

export const ORBIT_RATINGS = {
    mark: "5.0",
    clutch: "https://clutch.co/profile/newsoft?page=1#review-233931",
    points: [
        {
            mark: "5.0",
            title: "Quality",
            subtitle: "Service & Deliverables"
        },
        {
            mark: "5.0",
            title: "Cost",
            subtitle: "Value / Within Estimates"
        },
        {
            mark: "5.0",
            title: "Schedule",
            subtitle: "On Time / Deadlines"
        },
        {
            mark: "5.0",
            title: "Willing to Refer",
            subtitle: "NPS"
        },
    ]
}