import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import GridLayout from "../../layouts/GridLayout"
import { OUR_CASES } from "../../data/home/our-cases"
import { OneCase } from "../../features/one-case"
import { BookACall } from "../../features/book-a-call"
import DDFilter from "../../toolkits/DDFilter"
import { INDUSTRIES_OPTIONS, SERVICES_OPTIONS } from "../../constants/common"
import { useSearchParams } from "react-router-dom"

import "./index.scss"
import CloseIcon from "../../components/icons/CloseIcon"
import OneCaseSkeleton from "../../features/one-case-skeleton"

const Cases = () => {
    const [servicesFilter, setServicesFilter] = useState(SERVICES_OPTIONS.ALL)
    const [industryFilter, setIndustryFilter] = useState(INDUSTRIES_OPTIONS.ALL)
    const [filteredData, setFilteredData] = useState(OUR_CASES)
    const [searchParams, setSearchParams] = useSearchParams()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const service = searchParams.get("service")
        const industry = searchParams.get("industry")
        let preFiltered = OUR_CASES
        setLoading(true)

        if (service) {
            preFiltered = preFiltered.filter(o => 
                o.service.some(s => s.value === service)
            )
            setServicesFilter(SERVICES_OPTIONS[service.toUpperCase()])
        }

        if (industry) {
            preFiltered = preFiltered.filter(o => o.industry.value === industry)
            setIndustryFilter(INDUSTRIES_OPTIONS[industry.toUpperCase()])
        }
        setTimeout(() => {
            setFilteredData(preFiltered)
            setLoading(false)
        }, 1300)
    }, [searchParams])

    useEffect(() => {
        const params = { }
        if (servicesFilter !== SERVICES_OPTIONS.ALL) {
            params.service = servicesFilter.value
        }
        if (industryFilter !== INDUSTRIES_OPTIONS.ALL) {
            params.industry = industryFilter.value
        }
        setSearchParams(params)
    }, [servicesFilter, industryFilter, setSearchParams])

    const clearFilter = () => {
        setIndustryFilter(INDUSTRIES_OPTIONS.ALL)
        setServicesFilter(SERVICES_OPTIONS.ALL)
    }

    return (
        <>
            <Helmet defer={false}>
                <title>Cases - Newsoft</title>
            </Helmet>
            <GridLayout>
                <div className="cases">
                    <div className="cases-header">
                        <h1 className="t-h1-m">Our cases</h1>
                        <p className="t-s-14">Discover the power of our solutions through our detailed case studies. At Newsoft, we tackle complex challenges across various industries, delivering custom software development and staff augmentation services that drive success.</p>
                    </div>
                    <div className="cases-filters">
                        <DDFilter
                            title={"Service"}
                            value={{label: servicesFilter.label, value: servicesFilter.value}}
                            defaultValue={{label: servicesFilter.label, value: servicesFilter.value}}
                            options={(Object.values(SERVICES_OPTIONS)).filter(o => o.value !== "all").map((o) => ({label: o.label,  value: o.value}))}
                            onChange={(newFilter) => setServicesFilter(newFilter)}
                        />
                        <div className="cases-filters-industry">
                            <DDFilter
                                title={"Industry"}
                                value={{label: industryFilter.label, value: industryFilter.value}}
                                defaultValue={{label: industryFilter.label, value: industryFilter.value}}
                                options={(Object.values(INDUSTRIES_OPTIONS)).filter(o => o.value !== "all").map((o) => ({label: o.label,  value: o.value}))}
                                onChange={(newFilter) => setIndustryFilter(newFilter)}
                            />
                            {(servicesFilter !== SERVICES_OPTIONS.ALL || industryFilter !== INDUSTRIES_OPTIONS.ALL) && 
                            <p className="cases-filters-industry-clear t-s-12" onClick={() => clearFilter()}>
                                clear
                            </p>
                            }
                        </div>
                    </div>

                    {loading ? 
                        <div className="cases-list">
                            <OneCaseSkeleton/>
                            <OneCaseSkeleton reverse={true}/>
                            <OneCaseSkeleton/>
                        </div> :
                    
                        (filteredData.length > 0 ? <div className="cases-list">
                            {filteredData.map((el, index) => (
                                <OneCase
                                    alt={el.imageAlt}
                                    key={index}
                                    index={index}
                                    header={el.header}
                                    image={el.image}
                                    imageAlt={el.imageAlt}
                                    desc={el.desc}
                                    link={el.link}
                                    industry={el.industry}
                                    duration={el.duration}
                                    location={el.location}
                                    service={el.service}
                                    color={el.color}
                                />
                            ))}
                        </div> :
                            <div className="cases-empty">
                                <div className="cases-empty-text">
                                    <p className="t-s-16">There is no matching results.</p>
                                    <p className="t-s-16">Please try adjusting your filters..</p>
                                </div>
                                <div className="cases-empty-button" onClick={() => clearFilter()}>
                                    <p className="t-s-12">clear filters</p>
                                    <CloseIcon width={18} height={18}/> 
                                </div>
                            </div>
                        )
                    }
                </div>
            </GridLayout>
            <BookACall/>
        </>
    )
}

export default Cases
