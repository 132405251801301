import React from "react"
import { NAVIGATION_LINKS } from "../../constants/common"
import Clutch2019Icon from "../../assets/images/awards/Clutch2019.svg"
import Clutch2020Icon from "../../assets/images/awards/Clutch2020.svg"
import Clutch2021Icon from "../../assets/images/awards/Clutch2021.svg"
import ManifestAugmentationIcon from "../../assets/images/awards/ManifestAugmentation.svg"
import MobileDevelopmentIcon from "../../components/icons/MobileDevelopment"
import WebDevelopmentIcon from "../../components/icons/WebDevelopment"
import DataAnalyticsIcon from "../../components/icons/DataAnalytics"
import CrossPlatformDevelopmentIcon from "../../components/icons/CrossPlatformDevelopment"
import ECommerceSolutionIcon from "../../components/icons/ECommerceSolutions"


export const SPORT_AND_WELLNESS_HEADER = {
    image: "https://newsoft-ns.com/media/industries/sport-wellness-industry.webp",
    mobileImage: "https://newsoft-ns.com/media/industries/sport-wellness-industry_mobile.webp",
    header: "Sport & Wellness",
    link: NAVIGATION_LINKS.SPORT_AND_WELLNESS,
}

export const SPORT_AND_WELLNESS_OVERVIEW = [
    "The sport and wellness industry is an evolving and growing sector that covers a wide range of activities, products, and services. Disrupted by the Covid-19, the industry rebounded strongly reflecting the increasing interest in health awareness and participation in fitness activities.",
    "The impressive growth across various segments—sport tournaments, teams management, leagues, and personal training—highlights the immense potential for businesses ready to embrace transformation and digitalization.",
    "At Newsoft, we are committed to delivering cutting-edge solutions to give our clients a competitive advantage in the market. From creating personalized fitness platforms to developing advanced gym management systems, or launching innovative wellness products we are eager to take your business to the next level."
]

export const SPORT_AND_WELLNESS_CLUTCH_AWARDS = [
    {icon: Clutch2019Icon, alt: "Top B2B company 2019 - Newsoft Clutch"},
    {icon: Clutch2020Icon, alt: "Top B2B company 2020 - Newsoft Clutch"},
    {icon: Clutch2021Icon, alt: "Top Mobile App Developers 2021 - Newsoft Clutch"},
]

export const SPORT_AND_WELLNESS_MANIFEST_AWARDS = [
    {icon: ManifestAugmentationIcon, alt: "Staff Augmentation Company - Newsoft Manifest"},
]

export const SPORT_AND_WELLNESS_SOLUTIONS = {
    desc: "Explore the sport and wellness solutions Newsoft has developed over five years of working in the industry, and find the one that matches your business goals. Get in touch with us to find out more details on our expertise and solutions.",
    solutions: [
        {
            header: "Personalized sport and wellness solutions",
            text: "Transform sport services with a powerful platform offering unmatched personalization within your fitness platform, meditation and mindfulness app, or corporate wellness programs. Clients can select their favorite trainers, sports, or level. Elevate the satisfaction with easy-to-follow videos or workout plans, nutrition tips, tracking progress tools, and a rewards system. Grow the community of like-minded people and drive sales through an integrated online store all in one place.",
        },
        {
            header: "Real-Time Fan \nEngagement",
            text: "Elevate the fan experience and presence with the real-time fan engagement platform whether you're an esports team, a football league, or a golf tour, we’re excited about assisting you in putting the business at the forefront of the sport industry. Allow the sport fans to receive instant notifications, news, live commentary and ensure the best event coverage. Within the app, provide the customers with tickets and merchandise purchasing options to increase revenue streams. Strengthen your brand and build a community around it!",
        },
        {
            header: "Advanced Gym Management System",
            text: "Empower your gym members with a user-friendly mobile app or a web platform offering seamless control over their fitness activities. Allow easy booking, cancellations, and waitlist management for classes and courts and convenient access to the facility by a digital pass. Provide detailed trainer profiles and class information to support informed decisions. Simplify subscription and payment management while personalizing the gym experience to increase convenience and member engagement. Additionally, streamline management by providing real-time visibility of attendee numbers, allowing for more efficient gym operations.",
        },
        {
            header: "AI-Powered \nSolutions",
            text: "Harness the power of AI to elevate fitness and wellness experiences by providing personalized, data-driven insights and recommendations. With the help of advanced machine learning algorithms analyze users' workout patterns, dietary habits, and progress, offering tailored suggestions for improvement. Incorporate predictive analytics to forecast future performance, potential injuries, and optimal training plans. Integrate with wearable devices and fitness apps to gather comprehensive data, creating a view of users' health and fitness. Boost retention and satisfaction by delivering a truly personalized and intelligent fitness experience.",
        },
        {
            header: "Optimal Health \nManagement",
            text: "Support your customers in managing their health goals with a personalized app. Create customized profiles to manage nutritional, sport, or supplement intake plans, preferences, and goals. By offering comprehensive insights and plans, provide your customer with a tailored experience. Utilize interactive tools to track food intake or sport activity, monitor progress, and provide personalized health recommendations, enhancing customer satisfaction and loyalty.",
        },
        {
            header: "Athlete Performance Analytics",
            text: "Create a sophisticated tool for tracking and analyzing athlete performance metrics. Offer features, such as real-time data collection, detailed performance reports, and personalized training recommendations to spotlight areas for further improvements. Enhance coaching strategies and athlete development by creating dashboards with data-driven insights, improving outcomes for sports teams and individual athletes.",
        },
        {
            header: "Looking for a solution tailored to your business needs?",
            text: "Fill out the form to get in touch, and we'll reach out to answer all your questions!",
            link: NAVIGATION_LINKS.CONTACT_US
        },
    ]
}

export const SPORT_AND_WELLNESS_SERVICES = [
    {
        header: "Mobile App Development",
        desc: "Provide the customers with easy access to fitness programs, schedules, bookings, and member interactions.",
        icon: <MobileDevelopmentIcon width="60px" height="60px" viewBox="0 0 60 60"/>
    },
    {
        header: "Web Development",
        desc: "Grow online presence, membership registrations, and booking services. ",
        icon: <WebDevelopmentIcon width="60px" height="60px" viewBox="0 0 60 60"/>
    },
    {
        header: "Cross-Platform Development",
        desc: "Streamline development and deployment of mobile applications across multiple platforms.",
        icon: <CrossPlatformDevelopmentIcon width="60px" height="60px" viewBox="0 0 60 60"/>
    },
    {
        header: "Data Analytics",
        desc: "Generate insights into member preferences, attendance trends, performance metrics, and operational efficiencies.",
        icon: <DataAnalyticsIcon width="60px" height="60px" viewBox="0 0 60 60"/>
    },
    {
        header: "E-Commerce Solutions",
        desc: "Enable customers with purchasing tickets and merchandise products.",
        icon: <ECommerceSolutionIcon width="60px" height="60px" viewBox="0 0 60 60"/>
    }
]

export const SPORT_AND_WELLNESS_WORKFLOW = [
    {
        number: "01",
        header: "Consultation",
        text: "We set up meetings to identify your business needs, goals, and project requirements.",
    },
    {
        number: "02",
        header: "Planning",
        text: "We develop a detailed project plan to ensure a clear roadmap.",
    },
    {
        number: "03",
        header: "Design",
        text: "Our team creates user-friendly and visually appealing designs according to your objectives and brand.",
    },
    {
        number: "04",
        header: "Development & Testing",
        text: "Our team develops the software using best practices and carries out rigorous testing to ensure robust functionality and performance.",
    },
    {
        number: "05",
        header: "Deployment",
        text: "Our team launches your software and provides ongoing support to ensure error-free operation and constant improvement.",
    },
]

export const SPORT_AND_WELLNESS_CLIENTS = [
    "EvolveYou App Ltd", "Future Platforms"
]