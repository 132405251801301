import React from "react"
import ContentLoader from "react-content-loader"
import { useMediaQuery } from "react-responsive"
import { TABLET_WIDTH } from "../../constants/common"

const OneCaseSkeleton = ({ reverse, ...props }) => {
    const isMobile = useMediaQuery({maxWidth: TABLET_WIDTH})

    return <ContentLoader 
        speed={1.5}
        height={isMobile ? 270 : 256}
        backgroundColor="#4e4e4e"
        foregroundColor="#2a2929"
        gradientRatio={1.5}
        foregroundOpacity={0.5}
        {...props}
    >
        {isMobile ? (
            <>
                <rect x="0" y="0" rx="0" ry="0" width="100%" height="150" />
                <rect x="0" y="170" rx="0" ry="0" width="25%" height="15" />
                <rect x="0" y="200" rx="0" ry="0" width="100%" height="15" />
                <rect x="0%" y="220" rx="0" ry="0" width="75%" height="15" />
                <rect x="0%" y="250" rx="0" ry="0" width="15%" height="15" />
                <rect x="17%" y="250" rx="0" ry="0" width="15%" height="15" />
                <rect x="34%" y="250" rx="0" ry="0" width="15%" height="15" />
                <rect x="51%" y="250" rx="0" ry="0" width="20%" height="15" />
            </>
        ) : reverse ? (
            <>
                <rect x="60%" y="0" rx="0" ry="0" width="40%" height="256" />
                <rect x="0" y="20" rx="0" ry="0" width="20%" height="24" />
                <rect x="0" y="80" rx="0" ry="0" width="55%" height="24" />
                <rect x="0%" y="110" rx="0" ry="0" width="28%" height="24" />
                <rect x="0%" y="160" rx="0" ry="0" width="10%" height="20" />
                <rect x="11%" y="160" rx="0" ry="0" width="10%" height="20" />
                <rect x="22%" y="160" rx="0" ry="0" width="10%" height="20" />
                <rect x="33%" y="160" rx="0" ry="0" width="15%" height="20" />
            </>
        ) : (
            <>
                <rect x="0" y="0" rx="0" ry="0" width="40%" height="256" />
                <rect x="45%" y="20" rx="0" ry="0" width="20%" height="24" />
                <rect x="45%" y="80" rx="0" ry="0" width="55%" height="24" />
                <rect x="45%" y="110" rx="0" ry="0" width="28%" height="24" />
                <rect x="45%" y="160" rx="0" ry="0" width="10%" height="20" />
                <rect x="56%" y="160" rx="0" ry="0" width="10%" height="20" />
                <rect x="67%" y="160" rx="0" ry="0" width="10%" height="20" />
                <rect x="78%" y="160" rx="0" ry="0" width="15%" height="20" />
            </>
        )}
    </ContentLoader>
}

export default OneCaseSkeleton