import React from "react"
import BaseIcon from "../BaseIcon"

const OrbitIcon = ({...props }) => (
    <BaseIcon {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3225_12289)">
            <path d="M13.088 25C9.06465 25 5.87748 23.8647 3.52649 21.5942C1.1755 19.3237 0 16.2923 0 12.5C0 8.70773 1.1755 5.67633 3.52649 3.4058C5.87748 1.13527 9.06465 0 13.088 0C17.1841 0 20.4076 1.13527 22.7586 3.4058C25.1338 5.65217 26.3214 8.68357 26.3214 12.5C26.3214 16.3164 25.1338 19.3599 22.7586 21.6304C20.4076 23.8768 17.1841 25 13.088 25ZM7.41653 18.1522C8.5072 19.2391 10.3977 19.7826 13.088 19.7826C15.7783 19.7826 17.6809 19.2391 18.7958 18.1522C19.935 17.0652 20.5045 15.1812 20.5045 12.5C20.5045 9.81884 19.935 7.93478 18.7958 6.84783C17.6809 5.76087 15.7783 5.21739 13.088 5.21739C10.3977 5.21739 8.5072 5.76087 7.41653 6.84783C6.32587 7.93478 5.78053 9.81884 5.78053 12.5C5.78053 15.1812 6.32587 17.0652 7.41653 18.1522Z" fill="white"/>
            <path d="M34.1529 24.6377H28.6996V0.362318H42.1511C45.1565 0.362318 47.4712 1.00241 49.0951 2.28261C50.7432 3.53865 51.5672 5.32609 51.5672 7.64493C51.5672 11.7029 49.2768 13.9493 44.696 14.3841V14.6739C45.714 14.9396 46.4896 15.3382 47.0228 15.8696C47.5802 16.401 48.1135 17.1498 48.6224 18.1159L52.1489 24.6377H45.8231L42.4783 18.3333C41.9694 17.3671 41.3998 16.715 40.7696 16.3768C40.1395 16.0145 39.1336 15.8333 37.7521 15.8333H34.1529V24.6377ZM34.1529 5.25362V11.7391H42.1148C43.4478 11.7391 44.4052 11.5097 44.9869 11.0507C45.5686 10.5676 45.8594 9.71014 45.8594 8.47826C45.8594 7.29468 45.5565 6.46135 44.9505 5.97826C44.3688 5.49517 43.4236 5.25362 42.1148 5.25362H34.1529Z" fill="white"/>
            <path d="M69.0682 24.6377H53.7625V0.362318H68.3774C70.8738 0.362318 72.7764 0.869565 74.0852 1.88406C75.394 2.89855 76.0484 4.3599 76.0484 6.26812C76.0484 9.64976 74.158 11.4734 70.377 11.7391V12.0652C72.5826 12.2343 74.2428 12.8382 75.3577 13.8768C76.4968 14.8913 77.0664 16.2319 77.0664 17.8986C77.0664 20.0242 76.3999 21.6787 75.0668 22.8623C73.7338 24.0459 71.7343 24.6377 69.0682 24.6377ZM59.2158 5.25362V10H67.7594C68.7531 10 69.4681 9.81884 69.9044 9.45652C70.3649 9.07005 70.5951 8.46618 70.5951 7.64493C70.5951 6.79952 70.3649 6.19565 69.9044 5.83333C69.4438 5.44686 68.7046 5.25362 67.6867 5.25362H59.2158ZM59.2158 14.4928V19.7464H68.4138C69.5287 19.7464 70.3285 19.5531 70.8132 19.1667C71.298 18.756 71.5404 18.0676 71.5404 17.1014C71.5404 16.1594 71.298 15.4952 70.8132 15.1087C70.3285 14.6981 69.5287 14.4928 68.4138 14.4928H59.2158Z" fill="white"/>
            <path d="M84.5324 24.6377H79.079V0.362318H84.5324V24.6377Z" fill="white"/>
            <path d="M100.947 24.6377H95.4941V5.25362H86.4416V0.362318H110V5.25362H100.947V24.6377Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_3225_12289">
                <rect width="110" height="25" fill="white"/>
            </clipPath>
        </defs>
    </BaseIcon>
)

export default OrbitIcon
