import React from "react"
import BaseIcon from "../BaseIcon"

const TrynowIcon = ({ ...props }) => (
    <BaseIcon {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_732_14195" maskUnits="userSpaceOnUse" x="0" y="0" {...props}>
            <path d="M83.5886 0.889648H0V25.1084H83.5886V0.889648Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_732_14195)">
            <path d="M58.1077 4.42139C55.0384 4.42139 52.5508 6.89694 52.5508 9.95146V15.1244C52.5508 18.1789 55.0384 20.6545 58.1077 20.6545C61.1771 20.6545 63.6647 18.1789 63.6647 15.1244V9.95146C63.6647 6.89694 61.1771 4.42139 58.1077 4.42139ZM59.6079 15.3285C59.6079 16.1536 58.9369 16.8214 58.1077 16.8214C57.2786 16.8214 56.6076 16.1536 56.6076 15.3285C56.6076 15.1022 56.6076 10.1023 56.6076 9.74294C56.6076 8.91778 57.2786 8.25007 58.1077 8.25007C58.9369 8.25007 59.6079 8.91778 59.6079 9.74294C59.6079 9.96919 59.6079 14.9691 59.6079 15.3285Z" fill="white"/>
            <path d="M11.1228 8.49379V4.65178H4.05681V0.889648H0V15.1219C0 18.1764 2.48758 20.6519 5.55694 20.6519C8.62633 20.6519 11.1139 18.1764 11.1139 15.1219V11.3975H7.05708V15.326C7.05708 16.1511 6.38614 16.8189 5.55694 16.8189C4.72775 16.8189 4.05681 16.1511 4.05681 15.326V8.49152H11.1228V8.49379Z" fill="white"/>
            <path d="M20.4702 4.42361C18.8609 4.42361 17.8355 5.25988 17.1958 6.33573V4.65652H13.1791L13.168 20.3373H17.2248V9.74742C17.2248 8.92218 17.8958 8.2545 18.7249 8.2545C19.5542 8.2545 20.2251 8.92218 20.2251 9.74742V13.6759H24.2819V9.95148C24.2819 6.89694 23.0047 4.42139 20.4702 4.42139V4.42361Z" fill="white"/>
            <path d="M29.8897 20.6434C31.499 20.6434 32.5244 19.8071 33.1641 18.7313V25.1043H37.1808L37.192 4.72754H33.1351V15.3174C33.1351 16.1426 32.4642 16.8103 31.635 16.8103C30.8058 16.8103 30.1349 16.1426 30.1349 15.3174V4.72976H26.0781V15.1134C26.0781 18.1678 27.3553 20.6434 29.8897 20.6434Z" fill="white"/>
            <path d="M46.9703 4.42236C45.3609 4.42236 44.3355 5.25864 43.6958 6.33449V4.65528H39.6791L39.668 20.336H43.7249V9.7461C43.7249 8.92095 44.3958 8.25326 45.2249 8.25326C46.0542 8.25326 46.7251 8.92095 46.7251 9.7461V20.336H50.7819V9.95243C50.7819 6.89792 49.5047 4.42236 46.9703 4.42236Z" fill="white"/>
            <path d="M79.5316 4.73096V15.3208C79.5316 16.1461 78.8604 16.8137 78.0315 16.8137C77.2023 16.8137 76.5313 16.1461 76.5313 15.3208V15.1389C76.5313 15.1389 76.5358 15.1234 76.5358 15.1146V4.73096H72.4745V15.1146C72.4745 15.1146 72.4768 15.13 72.479 15.1389V15.3208C72.479 16.1461 71.8081 16.8137 70.9788 16.8137C70.1497 16.8137 69.4788 16.1461 69.4788 15.3208V4.73096H65.4219V15.1146C65.4219 18.169 67.1695 20.6446 70.1586 20.6446C72.5079 20.6446 73.9836 19.1894 74.5052 17.6189C75.029 19.1894 76.5023 20.6446 78.8517 20.6446C81.8412 20.6446 83.5887 18.169 83.5887 15.1146V4.73096H79.5316Z" fill="white"/>
        </g>
    </BaseIcon>
)

export default TrynowIcon
