import React from "react"
import { OUR_CASES } from "../../data/home/our-cases"
import { OneCase } from "../one-case"
import "./index.scss"
import AppLink from "../../components/AppLink"
import { NAVIGATION_LINKS } from "../../constants/common"
import { ArrowGetInTouch } from "../../components/icons/Arrow"

export const OurCases = ({
    header = "our cases",
    size = OUR_CASES.length,
    cases = OUR_CASES,
}) => {
    return (
        <div className="cases-wrapper">
            <div className="our-cases">
                <div className="our-cases-header">
                    <h3 className="t-h-m-22">{header}</h3>
                    <AppLink to={NAVIGATION_LINKS.CASES} className="cta-link t-s-14 clickable-link-white-background">
                        see all <ArrowGetInTouch color="#757579" />
                    </AppLink>
                </div>
                <div className="our-cases-list">
                    {cases.slice(0, size).map((el, index) => (
                        <OneCase
                            key={index}
                            index={index}
                            header={el.header}
                            image={el.image}
                            imageAlt={el.imageAlt}
                            desc={el.desc}
                            link={el.link}
                            industry={el.industry}
                            duration={el.duration}
                            location={el.location}
                            service={el.service}
                            color={el.color}
                            logoDesktop={el.logoDesktop}
                            logoMobile={el.logoMobile}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}
