import React from "react"
import "./index.scss"

export const ServiceServices = ({header, services}) => {
    return (
        <div className="service-template-services">
            <h3 className="t-h-m-22">{header}</h3>

            <div className="service-template-services-list">
                {services.map((el, index) => (
                    <div key={index} className="service-template-services-list-item">
                        <h4 className="t-s-16">{el.header}</h4>
                        <p className="t-s-14">{el.desc}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}
