import React, { useEffect } from "react"
import {
    OUR_SERVICES,
} from "../../data/home/our-services"
import { OurCases } from "../../features/our-cases"
import { ServicesCards } from "../../features/services-cards"
import Clutch2019Icon from "../../components/icons/Clutch2019"
import Clutch2020Icon from "../../components/icons/Clutch2020"
import Clutch2021Icon from "../../components/icons/Clutch2021"
import Clutch2023Icon from "../../components/icons/Clutch2023"
import Clutch2024ShopifyIcon from "../../components/icons/ClutchShopify2024"
import Clutch2024GamblingIcon from "../../components/icons/ClutchGambling2024"
import Clutch2024Icon from "../../components/icons/Clutch2024"
import { VideoBlock } from "../../features/video-block"
import { CompaniesIcons } from "../../features/icons"
import { Gallery } from "../../features/gallery"
import { Helmet } from "react-helmet-async"
import { BookACall } from "../../features/book-a-call"
import { OurArticles } from "../../features/our-articles"
import { NAVIGATION_LINKS } from "../../constants/common"
import { OurTestimonials } from "../../features/our-testimonials"
import TrynowIcon from "../../components/icons/clients/Trynow"
import IntelIcon from "../../components/icons/clients/Intel"
import EvolveYouIcon from "../../components/icons/clients/EvolveYou"
import FuturePlatformsIcon from "../../components/icons/clients/FuturePlatforms"
import PacksmithIcon from "../../components/icons/clients/Packsmith"
import CybellumIcon from "../../components/icons/clients/Cybellum"
import NeurocastIcon from "../../components/icons/clients/Neurocast"
import OrbitIcon from "../../components/icons/clients/Orbit"
import CraftIcon from "../../components/icons/clients/Craft"
import LocalMotionIcon from "../../components/icons/clients/LocalMotion"
import BazoIcon from "../../components/icons/clients/Bazo"
import HelloIcon from "../../components/icons/clients/Hello"
import { Banner } from "../../features/banner"

import "./index.scss"


const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Helmet defer={false}>
                <title>Newsoft - Software Development Company</title>
            </Helmet>
            <div className="home">
                <div className="home-intro">
                    <div className="home-intro-title">
                        <h1>
                            <span className="t-s1-m">Software development</span>{" "}
                        company providing businesses with top-notch
                            <span className="t-s1-m"> tech talent</span> and helping
                        build powerful
                            <span className="t-s1-m"> software products</span>
                        </h1>
                    </div>
                    <div className="home-intro-icons">
                        <CompaniesIcons/>
                        <div className="home-intro-icons-clutch">
                            <Clutch2019Icon width="90px" height="100px"/>
                            <Clutch2020Icon width="90px" height="100px"/>
                            <Clutch2021Icon width="90px" height="100px"/>
                            <Clutch2023Icon width="90px" height="100px"/>
                            <Clutch2024GamblingIcon width="90px" height="100px"/>
                            <Clutch2024Icon width="90px" height="100px"/>
                            <Clutch2024ShopifyIcon width="90px" height="100px"/>
                        </div>
                    </div>
                </div>
                <div className="home-services">
                    <ServicesCards
                        scrollHeader="Our services"
                        cardsArray={OUR_SERVICES}
                        link={NAVIGATION_LINKS.SERVISES}
                    />
                </div>
                <VideoBlock />
                <div className="home-icons">
                    <Banner images={[
                        () => <TrynowIcon width="85px" height="20px" viewBox="0 0 85 20" />,
                        () => <IntelIcon width="40px" height="25px" viewBox="0 0 40 25" />,
                        () => <EvolveYouIcon width="120px" height="25px" viewBox="0 0 120 25" />,
                        () => <FuturePlatformsIcon width="90px" height="35px" viewBox="0 0 90 35" />,
                        () => <CybellumIcon width="230px" height="30px" viewBox="0 0 230 30"/>,
                        () => <OrbitIcon width="110px" height="25px" viewBox="0 0 110 25" />,
                        () => <NeurocastIcon width="210px" height="35px" viewBox="0 0 210 35" />,
                        () => <CraftIcon width="138px" height="25px" viewBox="0 0 138 25" />,
                        () => <LocalMotionIcon width="110px" height="40px" viewBox="0 0 110 40" />,
                        () => <BazoIcon width="80px" height="30px" viewBox="0 0 80 30" />,
                        () => <PacksmithIcon width="190px" height="30px" viewBox="0 0 190 30"/>,
                        () => <HelloIcon width="91px" height="40px" viewBox="0 0 91 40"/>,
                    ]} speed={20000} />
                </div>
                <OurCases size={3}/>
            </div>
            <OurTestimonials />
            <Gallery />
            <OurArticles />
            <BookACall/>
        </>
    )
}

export default Home
