import React from "react"
import Clutch2019Icon from "../../assets/images/awards/Clutch2019.svg"
import Clutch2020Icon from "../../assets/images/awards/Clutch2020.svg"
import Clutch2021Icon from "../../assets/images/awards/Clutch2021.svg"
import Clutch2023Icon from "../../assets/images/awards/Clutch2023.svg"
import Clutch2023ShopifyIcon from "../../assets/images/awards/Clutch2023Shopify.svg"
import Clutch2024GamblingIcon from "../../assets/images/awards/Clutch2024Gambling.svg"
import Clutch2024Icon from "../../assets/images/awards/Clutch2024.svg"
import ManifestAugmentationIcon from "../../assets/images/awards/ManifestAugmentation.svg"
import ManifestECommerceIcon from "../../assets/images/awards/ManifestECommerce.svg"
import { DESKTOP_WIDTH } from "../../constants/common"
import { useMediaQuery } from "react-responsive"

import "./index.scss"
import LinkIcon from "../../components/icons/Link"
import { SOCIAL_MEDIA } from "../../constants/links"


export const AwardsBlock = () => {
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    return (
        <div className="awards">
            <div className="awards-header">
                <h3 className="t-h-m-22">Newsoft awards</h3>
            </div>
            <div className="awards-content">
                <div className="awards-content-clutch">
                    <div className="awards-content-clutch-title">
                        <h4 className="t-s-16">Clutch</h4>
                        <a href={SOCIAL_MEDIA.Clutch} target="_blank" rel="preload, noopener noreferrer nofollow"><LinkIcon /></a>
                    </div>

                    <div className="awards-content-clutch-list">
                        <img alt="top b2b company 2019 - newsoft clutch" src={Clutch2019Icon} width={isDesktop ? "129px" : "74px"} height={isDesktop ? "140px" : "80px"}/>
                        <img alt="top b2b company 2020 - newsoft clutch" src={Clutch2020Icon} width={isDesktop ? "129px" : "74px"} height={isDesktop ? "140px" : "80px"}/>
                        <img alt="top mobile app developers 2021 - newsoft clutch" src={Clutch2021Icon} width={isDesktop ? "129px" : "74px"} height={isDesktop ? "140px" : "80px"}/>
                        <img alt="top e-commerce developers 2023 - newsoft clutch" src={Clutch2023Icon} width={isDesktop ? "129px" : "74px"} height={isDesktop ? "140px" : "80px"}/>
                        <img alt="top shopify company 2023 - newsoft clutch" src={Clutch2023ShopifyIcon} width={isDesktop ? "129px" : "74px"} height={isDesktop ? "140px" : "80px"}/>
                        <img alt="top software developers 2024 - newsoft clutch" src={Clutch2024GamblingIcon} width={isDesktop ? "129px" : "74px"} height={isDesktop ? "140px" : "80px"}/>
                        <img alt="top e-commerce developers 2024 - newsoft clutch" src={Clutch2024Icon} width={isDesktop ? "129px" : "74px"} height={isDesktop ? "140px" : "80px"}/>
                    </div>

                </div>

                <div className="awards-content-manifest">
                    <div className="awards-content-manifest-title">
                        <h4 className="t-s-16"> The Manifest</h4>
                        <a href={SOCIAL_MEDIA.Manifest} target="_blank" rel="preload, noopener noreferrer nofollow"><LinkIcon /></a>
                    </div>

                    <div className="awards-content-manifest-list">
                        <img alt="e-commerce developers - newsoft manifest" src={ManifestECommerceIcon} width={isDesktop ? "96px" : "55px"} height={isDesktop ? "140px" : "80px"}/>
                        <img alt="staff augmentation company - newsoft manifest" src={ManifestAugmentationIcon} width={isDesktop ? "96px" : "55px"} height={isDesktop ? "140px" : "80px"}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
