import React, { useState, useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import { DESKTOP_WIDTH } from "../../constants/common"
import HeaderMenu from "../../components/icons/HeaderMenu"
import { ArrowGetInTouch } from "../../components/icons/Arrow"
import { Link } from "react-router-dom"
import { HEADER_NAVIGATION } from "../../data/header/navigation"
import { GetInTouch } from "../../utils"
import ArrowDown from "../../components/icons/ArrowDown"
import ArrowUp from "../../components/icons/ArrowUp"
import CloseIcon from "../../components/icons/CloseIcon"
import Expand from "react-expand-animated"


import "./index.scss"


const Header = () => {
    const isDesktop = useMediaQuery({ minWidth: DESKTOP_WIDTH })
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [scrollDirection, setScrollDirection] = useState(null)
    const [desktopSubMenuOpen, setDesktopSubMenuOpen] = useState({
        Services: false,
        Industries: false,
    })

    useEffect(() => {
        const handleMouseMove = (event) => {
            if (event.clientY < 70 && window.scrollY > 2) {
                setScrollDirection(true)
            }
        }

        window.addEventListener("mousemove", handleMouseMove)

        return () => {
            window.removeEventListener("mousemove", handleMouseMove)
        }
    }, [])

    const [openSections, setOpenSections] = useState({
        services: false,
        industries: false,
    })

    const handleToggleMenu = () => {
        if (isDesktop) {
            setIsMenuOpen(false)
        } else {
            setIsMenuOpen(!isMenuOpen)
        }

        if (isMenuOpen) {
            setOpenSections({
                services: false,
                industries: false,
            })
        }
    }

    const handleToggleSection = (section) => {
        setOpenSections((prevOpenSections) => ({
            ...prevOpenSections,
            [section]: !prevOpenSections[section],
        }))
    }

    useEffect(() => {
        let lastScrollY = window.scrollY

        const updateScrollDirection = () => {
            const scrollY = window.scrollY
            const direction = scrollY > lastScrollY ? false : true
            if (
                direction !== scrollDirection &&
                (scrollY - lastScrollY > 1 || scrollY - lastScrollY < -1)
            ) {
                if (isMenuOpen) {
                    handleToggleMenu()
                }
                setScrollDirection(direction)
            }
            lastScrollY = scrollY > 0 ? scrollY : 0
        }

        window.addEventListener("scroll", updateScrollDirection)
        return () => {
            window.removeEventListener("scroll", updateScrollDirection)
        }
    }, [scrollDirection])

    window.onscroll = function () {
        if (this.scrollY < 5) {
            setScrollDirection(null)
        }
    }

    const styles = {
        open: {visibility: "visible", overflow: "hidden", backgroundColor: "#0e0e0e", height: "250px", display: "flex", justifyContent: "center",
            position: "absolute",  width: "100%", gap: "24px", left: "0", opacity: 1,  zIndex: "1", padding: "60px 50px", borderBottom: "1px solid white"},
        close: {visibility: "hidden", overflow: "hidden", backgroundColor: "#0e0e0e", height: "0", display: "flex", justifyContent: "center",
            position: "absolute", width: "100%", gap: "24px", left: "0", opacity: 0, zIndex: "1", padding: "0px 50px", borderBottom: "1px solid white"},
    }

    return (
        <div className={`header ${scrollDirection || scrollDirection == null ? "show" : "hide"}`}>
            <div className="header-navigation">
                <Link
                    to="/"
                    target="_parent"
                    className="header-navigation-logo t-ns"
                >
                    Newsoft
                </Link>
                {!isDesktop && (
                    <div>
                        {isMenuOpen ? (
                            <CloseIcon width={30} height={30} onClick={handleToggleMenu} />
                        ) : (
                            <HeaderMenu width={30} height={30} onClick={handleToggleMenu} />
                        )}
                    </div>
                )}
                {isDesktop && (
                    <div className="header-navigation-desktop">
                        {HEADER_NAVIGATION.map((el, id) => (
                            <div key={id} className="header-navigation-desktop-items" 
                                onMouseEnter={() => el.items && setDesktopSubMenuOpen(prevState => ({...prevState,[el.header]: true}))}
                                onMouseLeave={() => el.items && setDesktopSubMenuOpen(prevState => ({...prevState,[el.header]: false}))}>
                                <hr className="items-divider"/>
                                <a href={el.link} onClick={() => handleToggleMenu()} className={"header-navigation-desktop-items-title t-s-14"}>
                                    {el.header}
                                </a>

                                <Expand
                                    open={desktopSubMenuOpen[el.header] || false}
                                    duration={300}
                                    styles={styles}
                                    transitions={["height", "opacity", "padding", "visibility"]}
                                >
                                    <div className="header-navigation-desktop-items-subitems">
                                        {el.items?.map((i, id) => (
                                            <Link
                                                to={i.link}
                                                onClick={() => handleToggleMenu()}
                                                key={id}
                                                className={"header-navigation-desktop-items-subitems-subitem"}
                                            >
                                                <hr className="divider" />
                                                <div>
                                                    <p className="title t-h-20">
                                                        {i.title}
                                                    </p>
                                                    <p className="t-s-14">
                                                        {i.text}
                                                    </p>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                </Expand>
                            </div>
                        ))}
                        <div className="header-menu-link">
                            <a onClick={() => GetInTouch()} className="t-s-16 clickable-link-black-background">
                                book a call
                                <ArrowGetInTouch width={26} color={"white"} />
                            </a>
                        </div>
                    </div>
                )}
            </div>
            {scrollDirection || isMenuOpen ? <hr className="border-up" /> : <></>}
            {isMenuOpen && !isDesktop && (
                <div className="header-menu">
                    <div className="header-menu-list">
                        {HEADER_NAVIGATION.map((navItem) => (
                            <div
                                key={navItem.header}
                                className={`header-menu-list-item${openSections[navItem.header.toLowerCase()] ? " active" : ""}`}
                            >
                                <div className="header-menu-list-item-dropdown">
                                    <Link to={navItem.link} className="t-s-14" onClick={() => handleToggleMenu()}>
                                        {navItem.header}
                                    </Link>
                                    {navItem.items && (
                                        openSections[navItem.header.toLowerCase()] ? (
                                            <ArrowUp onClick={() => handleToggleSection(navItem.header.toLowerCase())} />
                                        ) : (
                                            <ArrowDown onClick={() => handleToggleSection(navItem.header.toLowerCase())} />
                                        )
                                    )}
                                </div>
                                {navItem.items && (
                                    <ul className="header-menu-list-item-horizontally">
                                        {navItem.items.map((item) => (
                                            <Link
                                                to={item.link}
                                                key={item.title}
                                                className="t-s-14"
                                                onClick={() => handleToggleMenu()}
                                            >
                                                {item.title}
                                            </Link>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="header-menu-link">
                        <a onClick={() => GetInTouch()} className="t-s-18">
                            book a call
                            <ArrowGetInTouch width={26} color={"white"} />
                        </a>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Header
