import { NAVIGATION_LINKS } from "../../constants/common"

export const PACKSMITH_HEADER = {
    image: "https://newsoft-ns.com/media/cases/packsmith.webp",
    imageAlt: "Packsmith & Newsoft",
    header: "Packsmith",
    link: NAVIGATION_LINKS.PACKSMITH,
    desc: "IT Staff Augmentation for a distributed commerce logistics platform",
    industry: {label: "E-Commerce", value: "e_commerce"},
    duration: "2 years",
    location: "USA",
    service: [
        {label: "IT Staff Augmentation", value: "it_staff_augmentation"},
        {label: "API Development", value: "api_development"},
        {label: "Application Testing", value: "application_testing"},
        {label: "Custom Software Development", value: "custom_software_development"},
        {label: "Mobile App Development", value: "mobile_app_development"}
    ],
    color: "#003D41"
}

export const PACKSMITH_PROJECT_OVERVIEW = [
    "Packsmith streamlines delivery by optimizing home storage space, like garages, for a variety of products. The company prioritizes same-day delivery, job creation, and reducing the carbon footprint. Their dedicated mobile app enhances order packing and delivery, ensuring faster and cost-effective service. The Newsoft team was tasked with developing and maintaining Python microservices for e-commerce forecasting, implementing algorithms, and building integrations with assorted postal carriers.",
    "Team: Lead Python Developer, QA Engineer, FullStack Developer, and 2 iOS Developers."
]

export const PACKSMITH_CHALLENGES = [
    "Provide customers with a convenient system for shipment management",
    "Investigate and carry out the integration with postal services globally to streamline logistics and improve customer experience",
    "Optimize last-mile delivery costs and minimize overall shipment costs to increase profitability and offer competitive pricing",
    "Improve inventory management, ensure efficient stock levels, and reduce overhead costs",
    "Implement efficient billing and payout solutions",
    "Ensure high reliability and performance of the application"
]

export const PACKSMITH_SOLUTIONS = [
    "Designed and developed scalable and flexible core application for customers",
    "Integrated the application with postal services such as Australian Post, USPS, EasyPost and e-commerce platforms",
    "Created an algorithm that determines the most efficient way to pack and deliver items",
    "Implemented a custom inventory forecasting algorithm and developed an advanced system to cover different scenarios",
    "Integrated Stripe for secure and efficient payment processing",
    "Conducted thorough testing and quality assurance",
    "Continuously monitored and optimized workflows and processes to deliver an outstanding user experience"
]

export const PACKSMITH_CLIENT_FEEDBACK = {
    image: "https://newsoft-ns.com/media/clients/simon_robb.webp",
    name: "Simon Robb",
    desc: "Co-Founder & CTO",
    company: "Packsmith",
    linkedin: "https://www.linkedin.com/in/simon-robb-6341935b/",
    text: [
        "“The project with Newsoft has increased the client's engineering capacity. The team effectively plans timelines, communicates progress, and completes assigned tasks. Newsoft demonstrates teamwork They're a trustworthy team.",
        "Newsoft exhibits an exceptional company culture which values teamwork, excellence, and the ongoing growth and well-being of its employees. Working with Volodymyr has been nothing short of fantastic. His involvement has been instrumental in optimizing our companies' partnership, and I genuinely appreciate the trust we've built over this period.”"
    ],
}

export const PACKSMITH_RATINGS = {
    mark: "5.0",
    clutch: "https://clutch.co/profile/newsoft#review-243557",
    points: [
        {
            mark: "4.0",
            title: "Quality",
            subtitle: "Service & Deliverables"
        },
        {
            mark: "4.0",
            title: "Cost",
            subtitle: "Value / Within Estimates"
        },
        {
            mark: "4.5",
            title: "Schedule",
            subtitle: "On Time / Deadlines"
        },
        {
            mark: "5.0",
            title: "Willing to Refer",
            subtitle: "NPS"
        },
    ]
}