import React, { useEffect } from "react"
import GridLayout from "../../layouts/GridLayout"

import "./index.scss"
import { BookACall } from "../../features/book-a-call"
import { SOCIAL_MEDIA } from "../../constants/links"


const TermsAndConditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <GridLayout>
                <div className="terms-and-conditions">
                    <h1>Terms and Conditions</h1>
                    <div>
                        <p>
                            <strong>Updated 21.03.2024</strong>
                            <br /><br />
                        Welcome to Newsoft. These Terms and Conditions (&quot;Terms&quot;) govern your use of our website and services. By accessing or using our website and services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our website or services.
                            <br /><br />
                            <strong>Services</strong>
                            <br />
        Newsoft offers a range of IT services, including but not limited to software development, consulting, and technical support. Detailed descriptions of our services can be found on our website.
                            <br /><br />
                            <strong>Use of Services</strong>
                        </p>

                        <ul>
                            <li>
                                <strong>Eligibility</strong>
                                <br />
          You must be at least 18 years old to use our services. By using our services, you represent and warrant that you have the legal capacity to enter into these Terms.
                            </li>
                            <li>
                                <strong>User Responsibilities</strong>
                                <br />
          You agree to use our services in compliance with all applicable laws and regulations. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
                            </li>
                        </ul>

                        <p>
                            <strong>Privacy and Data Protection</strong>
                        </p>

                        <ul>
                            <li>
                                <strong>Data Collection</strong>
                                <br />
          We collect and use your personal data in accordance with our Privacy Policy. By using our services, you consent to such collection and use.
                            </li>
                            <li>
                                <strong>Data Security</strong>
                                <br />
          We implement reasonable security measures to protect your data. However, we cannot guarantee the absolute security of your data.
                            </li>
                        </ul>

                        <p>
                            <strong>Intellectual Property</strong>
                            <br />
        All content, trademarks, and data on our website and services are the property of Newsoft or its licensors. You may not use any of this content without prior written permission from us.
                            <br /><br />
                            <strong>Limitation of Liability</strong>
                            <br />
        To the fullest extent permitted by law, Newsoft shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use of our services.
                            <br /><br />
                            <strong>Disclaimer of Warranties</strong>
                            <br />
        Our services are provided &quot;as is&quot; and &quot;as available&quot; without any warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
                            <br /><br />
                            <strong>Governing Law</strong>
                            <br />
        These Terms are governed by and construed in accordance with the laws of Ukraine, without regard to its conflict of law principles.
                            <br /><br />
                            <strong>Changes to These Terms</strong>
                            <br />
        We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our website. Your continued use of our services after the changes become effective constitutes your acceptance of the new Terms.
                            <br /><br />
                            <strong>Contact Us</strong>
                            <br />
        If you have any questions or concerns about our Terms and Conditions or the way we handle your personal information, please contact us at{" "}
                            <a href={SOCIAL_MEDIA.SupportEmail}>support@newsoft-ns.com</a>.
                        </p>
                    </div>
                </div>
            </GridLayout>
            <BookACall />
        </>
    )
}

export default TermsAndConditions
