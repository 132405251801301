import React, { useState } from "react"
import { Controller } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import {
    OUR_TEAM_FIRST_RAW,
    OUR_TEAM_SECOND_RAW,
} from "../../data/home/our-team"
import LazyImage from "../../components/LazyImage"

import { SwiperButtonNext } from "../../components/swiper-buttons/ButtonNext"
import { SwiperButtonPrevious } from "../../components/swiper-buttons/ButtonPrevious"
import "swiper/css"
import "./index.scss"
import { useMediaQuery } from "react-responsive"
import { DESKTOP_WIDTH } from "../../constants/common"

export const Gallery = () => {
    const [firstSwiper, setFirstSwiper] = useState(null)
    const [secondSwiper, setSecondSwiper] = useState(null)    

    const [galleryPage, setGalleryPage] = useState(1)
    const isDesktop = useMediaQuery({ minWidth: DESKTOP_WIDTH })

    const handleSwipe = (swiper) => {
        if (swiper.swipeDirection === "prev") {
            setGalleryPage(galleryPage - 1)
        } else if (swiper.swipeDirection === "next") {
            setGalleryPage(galleryPage + 1)
        }
    }

    return (
        <div className="gallery">
            <div className="gallery-header">
                <h3 className="t-s3">Our Team</h3>
            </div>
            <Swiper
                modules={[Controller]}
                slidesPerView={
                    isDesktop
                        ? Math.round(window.innerWidth / 400)
                        : Math.round(window.innerWidth / 200)
                }
                loop
                controller={{ control: firstSwiper }}
                onSwiper={setSecondSwiper}
                className="swiper-first"
                allowTouchMove={true}
                simulateTouch={true}
                noSwiping={false}
                onSlideChange={(swipe) => handleSwipe(swipe)}
            >
                <div className="gallery-list-first">
                    {OUR_TEAM_FIRST_RAW.map((el, id) => (
                        <SwiperSlide key={el + id}>
                            <LazyImage
                                alt="photo from newsoft gallery"
                                src={el}
                                className="gallery-image"
                                visibleByDefault={id < 5}
                            />
                        </SwiperSlide>
                    ))}
                </div>
                {<div data-controls={true}>
                    <SwiperButtonPrevious
                        setGalleryPage={setGalleryPage}
                        className={"gallery-button-previous"}
                        galleryPage={galleryPage}
                    />

                    <SwiperButtonNext
                        galleryPage={galleryPage}
                        setGalleryPage={setGalleryPage}
                        className={"gallery-button-next"}
                    />
                </div>}
            </Swiper>
            <div className="gallery-scroller-wrapper">
                <div className="scroller">
                    <div className="horizontal-scrolling-items">
                        <div className="horizontal-scrolling-items__item">
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                        </div>
                        <div className="horizontal-scrolling-items__item">
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                            <p className="t-s3">GALLERY</p>
                        </div>
                    </div>
                </div>
            </div>
            <Swiper
                modules={[Controller]}
                slidesPerView={
                    isDesktop
                        ? Math.round(window.innerWidth / 400)
                        : Math.round(window.innerWidth / 200)
                }
                loop
                controller={{ control: secondSwiper }}
                className="swiper-second"
                onSwiper={setFirstSwiper}
                allowTouchMove={true}
                simulateTouch={true}
                noSwiping={false}
                onSlideChange={(swipe) => handleSwipe(swipe)}
            >
                <div className="gallery-list-second" data-controls={true}>
                    {OUR_TEAM_SECOND_RAW.map((el, id) => (
                        <SwiperSlide key={el + id}>
                            <LazyImage
                                alt="photo from newsoft gallery"
                                src={el}
                                className="gallery-image"
                                visibleByDefault={id < 5}
                            />
                        </SwiperSlide>
                    ))}
                </div>
            </Swiper>
        </div>
    )
}
