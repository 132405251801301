import React, { useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { OUR_ARTICLES } from "../../data/home/our-articles"
import { OneArticle } from "../one-acticle"
import "swiper/css"
import "./index.scss"
import { DESKTOP_WIDTH, MONITOR_WIDTH, TABLET_WIDTH } from "../../constants/common"
import { Navigation, Pagination } from "swiper"
import { useMediaQuery } from "react-responsive"
import { SwiperButtonPrevious } from "../../components/swiper-buttons/ButtonPrevious"
import { SwiperButtonNext } from "../../components/swiper-buttons/ButtonNext"

export const OurArticles = ({
    articles = OUR_ARTICLES,
}) => {
    const isMonitor = useMediaQuery({ minWidth: MONITOR_WIDTH })
    const isDesktop = useMediaQuery({ minWidth: DESKTOP_WIDTH })
    const isTablet = useMediaQuery({ minWidth: TABLET_WIDTH })

    const [cardNumber, setCardNumber] = useState(isMonitor ? 3 : isTablet ? 2 : 1)

    const handleSwipe = (swiper) => {
        if (swiper.swipeDirection === "prev") {
            setCardNumber(cardNumber - 1)
        } else if (swiper.swipeDirection === "next") {
            setCardNumber(cardNumber + 1)
        }
    }

    return (
        <div className="our-articles">
            <div className="our-articles-header">
                <h3 className="t-s3">Our articles</h3>
                <div className="our-articles-header-controls">
                    {isDesktop && (
                        <>
                            <SwiperButtonPrevious
                                className={`swiper-button-previous articles-previous ${cardNumber === 3 ? "disabled" : ""}`}
                                swipe={false}
                                disabled={cardNumber === 3}
                                setArticleNumber={setCardNumber}
                                articleNumber={cardNumber}
                            />
                            <SwiperButtonNext
                                className={`swiper-button-next articles-next ${cardNumber === articles.length ? "disabled" : ""}`}
                                swipe={false}
                                disabled={cardNumber === articles.length}
                                setArticleNumber={setCardNumber}
                                articleNumber={cardNumber}
                            />
                        </>
                    )}
                </div>
            </div>
            <Swiper
                spaceBetween={isDesktop ? 21 : 16}
                slidesPerView={isMonitor ? 3.1 : isTablet ? 2.1 : 1.05}
                allowTouchMove={true}
                simulateTouch={true}
                noSwiping={false}
                onSlideChange={(swipe) => handleSwipe(swipe)}
                pagination={{ clickable: true }}
                modules={[Pagination, Navigation]}
                navigation={{
                    nextEl: ".articles-next",
                    prevEl: ".articles-previous",
                }}
            >
                {articles.map((el, id) => (
                    <SwiperSlide key={id}>
                        <OneArticle el={el} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
