import React, { useState } from "react"
import "./index.scss"

const Banner = ({ images, speed = 2000, paused = false}) => {
    const [isPaused, setIsPaused] = useState(false)

    const handleMouseEnter = () => {
        if (paused) {
            setIsPaused(true)
        }
    }

    const handleMouseLeave = () => {
        if (paused) {
            setIsPaused(false)
        }
    }

    return (
        <div className="banner-inner">
            <div
                className="banner-wrapper"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {[...Array(3)].map((_, idx) => (
                    <section
                        key={idx}
                        style={{
                            "--speed": `${speed}ms`,
                            animationPlayState: isPaused ? "paused" : "running",
                        }}
                    >
                        {images.map((El, id) => (
                            <div className="image" key={id}>
                                <El key={id} />
                            </div>
                        ))}
                    </section>
                ))}
            </div>
        </div>
    )
}

export { Banner }
