import { NAVIGATION_LINKS } from "../../constants/common"

export const OUR_CASES = [
    {
        image: "https://newsoft-ns.com/media/cases/trynow.webp",
        imageAlt: "TryNow & Newsoft",
        header: "TryNow",
        desc: "Custom Software Development for an American Try Before You Buy project",
        link: NAVIGATION_LINKS.TRYNOW,
        industry: {label: "E-Commerce", value: "e_commerce"},
        duration: "3 years (ongoing)",
        location: "USA",
        service: [
            {label: "Custom Software Development", value: "custom_software_development"},
            {label: "Shopify App Development and Integration", value: "shopify_app_development_and_integration"}
        ],
        color: "#002E25"
    },
    {
        image: "https://newsoft-ns.com/media/cases/bazo.webp",
        imageAlt: "Bazo & Newsoft",
        header: "Bazo",
        desc: "Custom Software Development for a SAAS Solution",
        link: NAVIGATION_LINKS.BAZO,
        industry: {label: "E-Commerce", value: "e_commerce"},
        duration: "1 year (ongoing)",
        location: "USA",
        service: [
            {label: "Custom Software Development", value: "custom_software_development"},
            {label: "E-Commerce Development", value: "e_commerce_development"},
            {label: "Web Development", value: "web_development"},
            {label: "Shopify App Development and Integration", value: "shopify_app_development_and_integration"}
        ],
        color: "#008F70"
    },
    {
        image: "https://newsoft-ns.com/media/cases/packsmith.webp",
        imageAlt: "Packsmith & Newsoft",
        header: "Packsmith",
        link: NAVIGATION_LINKS.PACKSMITH,
        desc: "IT Staff Augmentation for a distributed commerce logistics platform",
        industry: {label: "E-Commerce", value: "e_commerce"},
        duration: "2 years",
        location: "USA",
        service: [
            {label: "IT Staff Augmentation", value: "it_staff_augmentation"},
            {label: "API Development", value: "api_development"},
            {label: "Application Testing", value: "application_testing"},
            {label: "Custom Software Development", value: "custom_software_development"},
            {label: "Mobile App Development", value: "mobile_app_development"}
        ],
        color: "#003D41"
    },
    {
        image: "https://newsoft-ns.com/media/cases/evolveyou.webp",
        imageAlt: "EvolveYou & Newsoft",
        header: "EvolveYou",
        desc: "IT Staff Augmentation for a global fitness company",
        link: NAVIGATION_LINKS.EVOLVEYOU,
        industry: {label: "Sport & Wellness", value: "sport_wellness"},
        duration: "1+ year (ongoing)",
        location: "United Kingdom",
        service: [
            {label: "IT Staff Augmentation", value: "it_staff_augmentation"},
            {label: "Mobile App Development", value: "mobile_app_development"},
            {label: "Web Development", value: "web_development"}
        ],
        color: "#1A7B85"
    },
    {
        image: "https://newsoft-ns.com/media/cases/hello.webp",
        imageAlt: "Hello & Newsoft",
        header: "Hello",
        desc: "Custom Software Development for an American Social Network",
        link: NAVIGATION_LINKS.HELLO,
        industry: {label: "Social Network", value: "social_network"},
        duration: "1,5 years",
        location: "USA",
        service: [
            {label: "Custom Software Development", value: "custom_software_development"},
            {label: "Mobile App Development", value: "mobile_app_development"}        
        ],
        color: "#513BBD"
    },
    {
        image: "https://newsoft-ns.com/media/cases/craft.webp",
        imageAlt: "Craft & Newsoft",
        header: "Craft",
        desc: "Custom Software Development for an American growth fund",
        link: NAVIGATION_LINKS.CRAFT,
        industry: {label: "Financial Services", value: "financial_services"},
        duration: "1,5 years",
        location: "USA",
        service: [
            {label: "Custom Software Development", value: "custom_software_development"},
        ],
        color: "#023047"
    },
    {
        image: "https://newsoft-ns.com/media/cases/orbit.webp",
        imageAlt: "Orbit & Newsoft",
        header: "Orbit",
        desc: "IT Staff Augmentation for an American recruitment agency",
        link: NAVIGATION_LINKS.ORBIT,
        industry: {label: "Staffing/Recruiting", value: "staffing_recruiting"},
        duration: "6 months",
        location: "USA",
        service: [
            {label: "IT Staff Augmentation", value: "it_staff_augmentation"}
        ],
        color: "#9C3F3F"
    },
    {
        image: "https://newsoft-ns.com/media/cases/pednet.webp",
        imageAlt: "PedNet & Newsoft",
        header: "PedNet",
        desc: "custom software development for non-profit American Organization",
        link: NAVIGATION_LINKS.PEDNET,
        industry: {label: "Environmental Solutions", value: "environmental_solutions"},
        duration: "1 year",
        location: "USA",
        service: [
            {label: "Custom Software Development", value: "custom_software_development"},
            {label: "Mobile App Development", value: "mobile_app_development"},
            {label: "Cross-Platform Development", value: "cross_platform_development"},
        ],
        color: "#A58231"
    }
]
