import React, {useEffect} from "react"
import { Helmet } from "react-helmet-async"
import GridLayout from "../../layouts/GridLayout"
import { BookACall } from "../../features/book-a-call"
import { ArrowGetInTouch } from "../../components/icons/Arrow"

import "./index.scss"
import { Link } from "react-router-dom"
import { NAVIGATION_LINKS } from "../../constants/common"
import { IndustryInto } from "../../features/industry-intro"
import { E_COMMERCE_HEADER } from "../../data/industries/e-commerce"
import { SPORT_AND_WELLNESS_HEADER } from "../../data/industries/sport_and_wellness"


const Industries = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet defer={false}>
                <title>Industries - Newsoft</title>
            </Helmet>
            <GridLayout>
                <div className="industries">
                    <div className="industries-header">
                        <h1 className="t-h1-m">Our industries</h1>
                        <p className="industries-header-desc t-s-14">At Newsoft, we bring our 5 years of extensive expertise and innovative solutions to the E-Commerce and Sport & Wellness industries. We understand the unique challenges and opportunities within each sector and are ready to meet the specific needs of your industry, ensuring you receive tailored solutions that drive efficiency and growth. </p>
                        <Link className="industries-header-cta clickable-link-black-background" to={NAVIGATION_LINKS.CONTACT_US}>
                            <p className="t-s-14">get in touch</p>
                            <ArrowGetInTouch width={18} color={"#B7B7B7"} />
                        </Link>
                    </div>

                    <div className="industries-list">
                        {[E_COMMERCE_HEADER, SPORT_AND_WELLNESS_HEADER].map((el, id) => (
                            <IndustryInto key={id} industry={el}/>
                        ))}
                    </div>
                </div>
            </GridLayout>
            <BookACall/>
        </>
    )
}

export default Industries
