import React, { useEffect, useState } from "react"
import "./index.scss"
import { Helmet } from "react-helmet-async"
import DonateQR from "../../assets/images/donate/donate.png"

const Donate = () => {
    const [jar, setJar] = useState(null)

    const updateJar = async () => {
        const response = await fetch(
            "https://api.monobank.ua/bank/jar/4rboqVRrHc7yp2MBwi2gFn23YruzTLND",
        )
        const data = await response.json()
        setJar(data)
    }

    useEffect(() => {
        void updateJar()
        setInterval(updateJar, 20000)
    }, [])

    const getPercents = () => {
        return Math.floor(jar.amount / jar.goal * 100)
    }

    const getThousands = (amount) => {
        return Math.floor(amount / 100_00) / 10
    }

    return (
        <>
            <Helmet defer={false}>
                <title>Donate - Newsoft</title>
            </Helmet>
            {
                jar && (
                    <div className="donate">
                        <div className="donate-content">
                            <div className="donate-content-main">
                                <div className="donate-content-main-progress" style={{
                                    background: `linear-gradient(to right, #4eade2 ${Math.min(getPercents() + 1, 100)}%, #fade52 ${Math.min(getPercents() + 1, 100)}% 100%)`,
                                }}>
                                    <div className="donate-content-main-progress-percent">
                                        {getPercents()}%
                                    </div>
                                </div>

                                <div className="donate-content-main-info">
                                    <div className="donate-content-main-info-title">
                                        <p className="donate-content-main-info-title-jar">{jar.title}</p>

                                        <div className="donate-content-main-info-title-money">
                                            <p>{getThousands(jar.amount)}K</p>
                                            <p>/</p>
                                            <p>{getThousands(jar.goal)}K</p>
                                        </div>

                                    </div>

                                    <div className="donate-content-main-info-qr">
                                        <img src={DonateQR} alt="qr code for donate" loading="lazy"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                )
            }

        </>
    )
}


export default Donate
