import React, { useEffect } from "react"
import BigNumList from "../../features/big-num-list"
import { OUR_VALUES } from "../../data/about-us/our-values"
import RoundedList from "../../features/rounded-list"
import { OUR_STRENGTH } from "../../data/about-us/our_strength"
import { Gallery } from "../../features/gallery"
import { MetricsBlock } from "../../features/metrics-block"
import { ABOUT_US_METRICS } from "../../data/about-us/metrics"
import { NewsoftPeople } from "../../features/newsoft-people"
import "./index.scss"
import LazyImage from "../../components/LazyImage"
import OurHistory from "../../features/our-history"
import { Helmet } from "react-helmet-async"
import { AwardsBlock } from "../../features/awards"
import { BookACall } from "../../features/book-a-call"

const AboutUs = () => {  
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    
    return (
        <>
            <Helmet defer={false}>
                <title>About us - Newsoft</title>
            </Helmet>
            <div className="about-us">
                <div className="about-us-header">
                    <h1 className="t-h1-m">Meet Newsoft</h1>
                    <div className="about-us-header-video">
                        <iframe
                            src="https://www.youtube.com/embed/w8rK2l1ZZoA"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={{ border: "none" }}
                        ></iframe>
                    </div>
                </div>
                <div>
                    <BigNumList
                        header="Our values"
                        list={OUR_VALUES}
                        className="big-num-list-dark"
                    />
                </div>
                <RoundedList className={"about-us-strengths"} header="Our strengths" list={OUR_STRENGTH} />
                <NewsoftPeople />
                <MetricsBlock list={ABOUT_US_METRICS} />
                <AwardsBlock/>
                <div
                    className="about-us-activities"
                >
                    <h3 className="t-s3">Our activities</h3>
                    <div className="about-us-activities-teambuildings">
                        <div className="about-us-activities-teambuildings-images">
                            <LazyImage
                                id="teambuilding-1"
                                src="https://newsoft-ns.com/media/about-us/activities/teambuilding-karting.webp"
                                alt="newsoft teambuilding"
                            />
                            <LazyImage
                                id="teambuilding-2"
                                src="https://newsoft-ns.com/media/about-us/activities/teambuilding-updates.webp"
                                alt="newsoft teambuilding"
                            />
                            <LazyImage
                                id="teambuilding-3"
                                src="https://newsoft-ns.com/media/about-us/activities/teambulding-medicine.webp"
                                alt="newsoft teambuilding"
                            />
                        </div>
                        <div className="about-us-activities-desc">
                            <h4 className="t-s-16">Team-buildings</h4>
                            <hr />
                            <p className="t-s-14">
                                At Newsoft, we highly value spending quality time
                                together and regularly organize team building
                                events.
                            </p>
                            <br />
                            <p className="t-s-14">
                                We enjoy outdoor activities together, such as
                                go-karting, paintball and trips to the
                                Carpathians.
                            </p>
                        </div>
                    </div>
                    <div className="about-us-activities-sport">
                        <div className="about-us-activities-sport-images">
                            <LazyImage
                                id="sport-1"
                                src="https://newsoft-ns.com/media/about-us/activities/sport-tennis.webp"
                                alt="newsoft sport"
                            />
                            <LazyImage
                                id="sport-4"
                                src="https://newsoft-ns.com/media/about-us/activities/sport-tournaments.webp"
                                alt="newsoft sport"
                            />
                            <LazyImage
                                id="sport-3"
                                src="https://newsoft-ns.com/media/about-us/activities/sport-football.webp"
                                alt="newsoft sport"
                            />
                        </div>
                        <div className="about-us-activities-desc">
                            <h4 className="t-s-16">Sport</h4>
                            <hr />
                            <p className="t-s-14">
                                We are committed to an active lifestyle within
                                our team and actively use gym facilities in our
                                office. Alongside we organize Tennis and
                                PlayStation tournaments
                            </p>
                            <br />
                            <p className="t-s-14">
                                The Newsoft team won first place in the IT
                                league of the Table Tennis Championship 2021 in
                                Lviv.
                            </p>
                        </div>
                    </div>
                    <div className="about-us-activities-events">
                        <div className="about-us-activities-events-images">
                            <LazyImage
                                id="events-1"
                                src="https://newsoft-ns.com/media/about-us/activities/events3.webp"
                                alt="newsoft events"
                            />
                            <LazyImage
                                id="events-2"
                                src="https://newsoft-ns.com/media/about-us/activities/events1.webp"
                                alt="newsoft events"
                            />
                            <LazyImage
                                id="events-3"
                                src="https://newsoft-ns.com/media/about-us/activities/events2.webp"
                                alt="newsoft events"
                            />
                        </div>
                        <div className="about-us-activities-desc">
                            <h4 className="t-s-16">Events</h4>
                            <hr />
                            <p className="t-s-14">
                                We frequently organize events to spend time
                                together. These events include company updates,
                                board, games and snacks, giving us a chance to
                                relax and build stronger relationships outside
                                of work.
                            </p>
                            <br />
                            <p className="t-s-14">
                                Creating a positive and enjoyable work
                                environment is important to us, as it fosters
                                teamwork, boosts values, and contributes to our
                                overall success as a team.
                            </p>
                        </div>
                    </div>
                </div>
                <OurHistory />
            </div>
            <Gallery />
            <BookACall />
        </>
    )
}

export default AboutUs
