import React from "react"
import "./index.scss"
import {OUR_MANAGEMENT } from "../../data/about-us/people"
import { PeopleCard } from "../people-card"

export const NewsoftPeople = () => {
    return (
        <div className="newsoft-people-wrapper">
            <div className="newsoft-people">
                <h3 className="t-h-m-22">Our management</h3>
                <div className="newsoft-people-list">
                    {OUR_MANAGEMENT.map((person) => (
                        <PeopleCard key={person.image} {...person} />
                    ))}
                </div> 
            </div>
        </div>
    )
}
