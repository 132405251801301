import React from "react"
import { Link } from "react-router-dom"
import NewsoftLogo from "../../assets/images/logoNewsoft.svg"
import { SOCIAL_MEDIA } from "../../constants/links"
import LinkedinIcon from "../../assets/images/social_media/Linkedin.svg"
import InstagramIcon from "../../assets/images/social_media/Instagram.svg"
import FacebookIcon from "../../assets/images/social_media/Facebook.svg"
import YouTubeIcon from "../../assets/images/social_media/Youtube.svg"
import ClutchIcon from "../../assets/images/social_media/Clutch.svg"
import MeduimIcon from "../../assets/images/social_media/Medium.svg"


import "./index.scss"
import { NAVIGATION_LINKS } from "../../constants/common"

const Footer = () => {

    return (
        <div className="footer">
            <hr className="footer-divider"/>
            <div className="footer-content">
                <div className="footer-content-icons">
                    <div className="footer-content-icons-logo">
                        <img src={NewsoftLogo} alt="newsoft logo"></img>
                    </div>
                    <div className="footer-content-icons-social">
                        <Link
                            className="hov-icon"
                            to={SOCIAL_MEDIA.LinkedIn}
                            target="_blank"
                            rel="preload, noopener noreferrer nofollow"
                        >
                            <img src={LinkedinIcon} alt="linkedIn logo"/>
                        </Link>
                        <Link
                            className="hov-icon"
                            to={SOCIAL_MEDIA.Instagram}
                            target="_blank"
                            rel="preload, noopener noreferrer nofollow"
                        >
                            <img src={InstagramIcon} alt="instagram logo"/>
                        </Link>
                        <Link 
                            className="hov-icon"
                            to={SOCIAL_MEDIA.YouTube}
                            target="_blank"
                            rel="preload, noopener noreferrer nofollow"
                        >
                            <img src={YouTubeIcon} alt="youtube logo"/>
                        </Link>
                        <Link
                            className="hov-icon"
                            to={SOCIAL_MEDIA.Facebook}
                            target="_blank"
                            rel="preload, noopener noreferrer nofollow"
                        >
                            <img src={FacebookIcon} alt="facebook logo"/>
                        </Link>
                        <Link
                            className="hov-icon"
                            to={SOCIAL_MEDIA.Clutch}
                            target="_blank"
                            rel="preload, noopener noreferrer nofollow"
                        >
                            <img src={ClutchIcon} alt="clutch logo"/>
                        </Link>
                        <Link
                            className="hov-icon"
                            to={SOCIAL_MEDIA.Medium}
                            target="_blank"
                            rel="preload, noopener noreferrer nofollow"
                        >
                            <img src={MeduimIcon} alt="medium logo"/>
                        </Link>
                    </div>
                </div>
                <hr className="footer-content-vertical" />
                <div className="footer-content-navigation">
                    <div className="footer-content-navigation-block">
                        <p className="footer-content-navigation-block-title t-s5">
                                Services
                        </p>
                        <Link
                            to={NAVIGATION_LINKS.IT_STAFF_AUGMENTATION}
                            className="footer-content-navigation-block-link t-b4"
                        >
                                IT Staff Augmentation
                        </Link>
                        <Link
                            to={NAVIGATION_LINKS.CUSTOM_SOFTWARE_DEVELOPMENT}
                            className="footer-content-navigation-block-link t-b4"
                        >
                                Custom Software Development
                        </Link>
                    </div>

                    <div className="footer-content-navigation-block">
                        <p className="footer-content-navigation-block-title t-s5">
                            Industries
                        </p>
                        <Link
                            to={NAVIGATION_LINKS.E_COMMERCE}
                            className="footer-content-navigation-block-link t-b4"
                        >
                            E-Commerce
                        </Link>
                        <Link
                            to={NAVIGATION_LINKS.SPORT_AND_WELLNESS}
                            className="footer-content-navigation-block-link t-b4"
                        >
                            Sport & Wellness
                        </Link>
                    </div>

                    <div className="footer-content-navigation-block">
                        <p className="footer-content-navigation-block-title t-s5">
                            Pages
                        </p>
                        <Link
                            to={NAVIGATION_LINKS.CASES}
                            className="footer-content-navigation-block-link t-b4"
                        >
                                Our Cases
                        </Link>
                        <Link
                            to={NAVIGATION_LINKS.ABOUT_US}
                            className="footer-content-navigation-block-link t-b4"
                        >
                                About Us
                        </Link>
                        <Link
                            to={NAVIGATION_LINKS.CONTACT_US}
                            className="footer-content-navigation-block-link t-b4"
                        >
                                Contact Us
                        </Link>
                    </div>

                    <div className="footer-content-navigation-block">
                        <p className="footer-content-navigation-block-title t-s5">
                                Policies
                        </p>
                        <Link
                            to={NAVIGATION_LINKS.PRIVACY_POLICY}
                            className="footer-content-navigation-block-link t-b4"
                        >
                                Privacy Policy
                        </Link>

                        <Link
                            to={NAVIGATION_LINKS.TERMS_AND_CONDITIONS}
                            className="footer-content-navigation-block-link t-b4"
                        >
                                Terms and Conditions
                        </Link>
                    </div>
                </div>
            </div>
            <hr />
            <div className="footer-copyright">
                <p className="t-s-12">© 2019-{new Date().getFullYear()} Newsoft </p>
            </div>
        </div>
    )
}

export default Footer
