import React, {useEffect} from "react"
import { Helmet } from "react-helmet-async"
import { BookACall } from "../../features/book-a-call"

import "./index.scss"
import { FAQ } from "../../features/faq"
import { FAQ_CONTENT } from "../../data/contact-us/faq"
import { Link } from "react-router-dom"
import { SOCIAL_MEDIA } from "../../constants/links"
import LinkedinIcon from "../../assets/images/social_media/Linkedin.svg"
import InstagramIcon from "../../assets/images/social_media/Instagram.svg"
import FacebookIcon from "../../assets/images/social_media/Facebook.svg"
import YouTubeIcon from "../../assets/images/social_media/Youtube.svg"
import ClutchIcon from "../../assets/images/social_media/Clutch.svg"
import MeduimIcon from "../../assets/images/social_media/Medium.svg"
import MapsIcon from "../../assets/images/social_media/Maps.svg"
import MessageIcon from "../../assets/images/social_media/Message.svg"
import EmailIcon from "../../assets/images/social_media/Email.svg"
import SendEmail from "../../features/send-email"


const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet defer={false}>
                <title>Contact Us - Newsoft</title>
            </Helmet>
            <div className="contact-us">
                <div className="contact-us-header">
                    <h1>Contact us</h1>
                    <p className="contact-us-header-desc t-s-14">Any questions? We would be happy to help you!</p>
                </div>

                <div className="contact-us-info">
                    <SendEmail/>

                    <div className="contact-us-info-socials">
                        <div className="contact-us-info-socials-chat">
                            <h3 className="t-s-16">Chat with us</h3>
                            <div className="contact-us-info-socials-chat-links">
                                <Link
                                    className="hov-icon"
                                    to={SOCIAL_MEDIA.VolodymyrTkachCalendy}
                                    
                                    target="_blank"
                                    rel="preload, noopener noreferrer nofollow"
                                >
                                    <img src={MessageIcon} alt="message logo"/>
                                    Book a call with CEO
                                </Link>

                                <Link
                                    className="hov-icon"
                                    to={SOCIAL_MEDIA.SalesEmail}
                                    
                                    target="_blank"
                                    rel="preload, noopener noreferrer nofollow"
                                >
                                    <img src={EmailIcon} alt="email logo"/>
                                    Send us an email
                                </Link>

                                <Link
                                    className="hov-icon"
                                    to={SOCIAL_MEDIA.LinkedIn}
                                    
                                    target="_blank"
                                    rel="preload, noopener noreferrer nofollow"
                                >
                                    <img src={LinkedinIcon} alt="linkedIn logo"/>
                                    Send a message in LinkedIn
                                </Link>
                                
                            </div>

                        </div>

                        <div className="contact-us-info-socials-visit">
                            <h3 className="t-s-16">Visit us</h3>
                            <div className="contact-us-info-socials-visit-links">
                                <Link
                                    className="hov-icon"
                                    to={SOCIAL_MEDIA.Location}
                                    
                                    target="_blank"
                                    rel="preload, noopener noreferrer nofollow"
                                >
                                    <img src={MapsIcon} alt="maps logo"/>
                                    Zelena 149A Street, 79035, Lviv, Ukraine
                                </Link>
                            </div>
                        </div>

                        <div className="contact-us-info-socials-follow">
                            <h3 className="t-s-16">Follow us</h3>
                            <div className="contact-us-info-socials-follow-links">
                                <Link
                                    className="hov-icon"
                                    to={SOCIAL_MEDIA.LinkedIn}
                                    
                                    target="_blank"
                                    rel="preload, noopener noreferrer nofollow"
                                >
                                    <img src={LinkedinIcon} alt="linkedIn logo"/>
                                    LinkedIn
                                </Link>

                                <Link
                                    className="hov-icon"
                                    to={SOCIAL_MEDIA.Medium}
                                    target="_blank"
                                    rel="preload, noopener noreferrer nofollow"
                                >
                                    <img src={MeduimIcon} alt="medium logo"/>
                                    Medium
                                </Link>

                                <Link
                                    className="hov-icon"
                                    to={SOCIAL_MEDIA.Clutch}
                                    target="_blank"
                                    rel="preload, noopener noreferrer nofollow"
                                >
                                    <img src={ClutchIcon} alt="clutch logo"/>
                                    Clutch
                                </Link>

                                <Link
                                    className="hov-icon"
                                    to={SOCIAL_MEDIA.Instagram}
                                    target="_blank"
                                    rel="preload, noopener noreferrer nofollow"
                                >
                                    <img src={InstagramIcon} alt="instagram logo"/>
                                    Instagram
                                </Link>

                                <Link
                                    className="hov-icon"
                                    to={SOCIAL_MEDIA.YouTube}
                                    target="_blank"
                                    rel="preload, noopener noreferrer nofollow"
                                >
                                    <img src={YouTubeIcon} alt="youtube logo"/>
                                    YouTube
                                </Link>

                                <Link
                                    className="hov-icon"
                                    to={SOCIAL_MEDIA.Facebook}
                                    target="_blank"
                                    rel="preload, noopener noreferrer nofollow"
                                >
                                    <img src={FacebookIcon} alt="facebook logo"/>
                                    Facebook
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>

                <FAQ items={FAQ_CONTENT} />
            </div>
            <BookACall/>
        </>
    )
}

export default ContactUs
