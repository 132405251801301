import React from "react"
import "./index.scss"

const BigNumList = ({ header, className, list, mode }) => {
    return (
        <div className={`big-num-list ${className ? className : " "} ${mode ? mode : " "}`}>
            <h3 className="t-s3">{header}</h3>
            <div className="big-num-list-block">
                {list.map((el, i) => (
                    <div key={i}>
                        <div className="big-num-list-block-item" key={el.number}>
                            <p className="big-num-list-block-item-number">{el.number}</p>
                            <div className="big-num-list-block-item-content">
                                <h4 className="t-s-16">{el.header}</h4>
                                <p className="t-s-14">{el.text}</p>
                            </div>
                        </div>
                        {i != list.length -1 && <hr/>}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default BigNumList
