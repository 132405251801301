import React from "react"
import { Link } from "react-router-dom"


const AppLink = ({ to, href, children, className }) => {
    return to ? (
        <Link className={`${className ? className : ""}`} to={to}>
            {children}
        </Link>
    ) : (
        <a
            className={`${className ? className : ""}`}
            target="_blank"
            rel="noopener noreferrer nofollow"
            href={href}
        >
            {children}
        </a>
    )
}

export default AppLink
