import React from "react"
import "./index.scss"

const RoundedList = ({ header, className, list }) => {
    return (
        <div className={`rounded-list ${className ? className : " "}`}>
            <h3 className="t-s3">{header}</h3>
            <div className="rounded-list-items">
                {list.map((item) => (
                    <div className="rounded-list-item t-b3" key={item}>
                        {item}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default RoundedList
