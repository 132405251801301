import React from "react"
import { NAVIGATION_LINKS } from "../../constants/common"
import Clutch2019Icon from "../../assets/images/awards/Clutch2019.svg"
import Clutch2020Icon from "../../assets/images/awards/Clutch2020.svg"
import Clutch2021Icon from "../../assets/images/awards/Clutch2021.svg"
import Clutch2023Icon from "../../assets/images/awards/Clutch2023.svg"
import Clutch2023ShopifyIcon from "../../assets/images/awards/Clutch2023Shopify.svg"
import ManifestAugmentationIcon from "../../assets/images/awards/ManifestAugmentation.svg"
import ManifestECommerceIcon from "../../assets/images/awards/ManifestECommerce.svg"
import MobileDevelopmentIcon from "../../components/icons/MobileDevelopment"
import WebDevelopmentIcon from "../../components/icons/WebDevelopment"
import DataAnalyticsIcon from "../../components/icons/DataAnalytics"
import CybersecurityIcon from "../../components/icons/Cybersecurity"
import ApiIntegrationIcon from "../../components/icons/ApiIntegration"
import MachineLearningIcon from "../../components/icons/MachineLearning"

export const E_COMMERCE_HEADER = {
    image: "https://newsoft-ns.com/media/industries/e-commerce-industry.webp",
    mobileImage: "https://newsoft-ns.com/media/industries/e-commerce-industry_mobile.webp",
    header: "E-Commerce",
    link: NAVIGATION_LINKS.E_COMMERCE
}

export const E_COMMERCE_OVERVIEW = [
    "Transforming the way people shop and operate their businesses, e-commerce has proven to be an industry with a steady growth. Projected to reach $7.9 trillion in 2027, the industry is responding to the less interest in physical shopping. Moreover, by 2027, 23% of retail purchases are expected to take place online according to Forbes.",
    "The growing value of Business-to-Consumer (B2C) and Business-to-Business (B2B) operation systems provides its end-customers with seamless user experience, offering personalized recommendations and providing easy checkout options, and streamlined ordering process, allowing buyers to order products in bulk, manage their inventory and track orders in real-time.",
    "At Newsoft, we're keen to help you stay ahead of the game and tackle your business challenges, providing the top-notch expertise and technologies."
]

export const E_COMMERCE_CLUTCH_AWARDS = [
    {icon: Clutch2019Icon, alt: "Top B2B company 2019 - Newsoft Clutch"},
    {icon: Clutch2020Icon, alt: "Top B2B company 2020 - Newsoft Clutch"},
    {icon: Clutch2021Icon, alt: "Top Mobile App Developers 2021 - Newsoft Clutch"},
    {icon: Clutch2023ShopifyIcon, alt: "Top Shopify Company 2023 - Newsoft Clutch"},
    {icon: Clutch2023Icon, alt: "Top E-Commerce Developers 2023 - Newsoft Clutch"}
]

export const E_COMMERCE_MANIFEST_AWARDS = [
    {icon: ManifestAugmentationIcon, alt: "Staff Augmentation Company - Newsoft Manifest"},
    {icon: ManifestECommerceIcon, alt: "E-Commerce Developers - Newsoft Manifest"}
]

export const E_COMMERCE_SOLUTIONS = {
    desc: "Discover the e-commerce solutions Newsoft has crafted over five years of working in the industry, and find the perfect match for your business goals. Contact us to learn more about our expertise and solutions.",
    solutions: [
        {
            header: "Innovative Shipping Solutions",
            text: "Simplify logistics of your shipment services, reduce cost, and make your clients happier with faster and more reliable shipping. Integrate your platform with major carriers, such as Australian Post, USPS, and EasyPost, all over the world and automate processes directly from your e-commerce platform. Provide your customers with real-time shipment tracking and notifications to enhance their user experience and build trust. Access discounted rates from top carriers, compare shipping rates, and select the most cost-efficient option for each delivery. Help yourself by creating a convenient inventory and collateral management system to avoid stock-outs and overselling.",
        },
        {
            header: "Advanced Tracking and Analytics Solutions",
            text: "Transform your tracking and analytics processes with more advanced features to make more informed decisions. Increase transparency and trust by providing up-to-the-minute updates on the clients’ orders, create a dashboard with metrics on delivery time, shipment costs, or carrier performance. To identify areas for improvement and growth, monitor customer feedback and delivery success rates and track shipment expenses in detail to spot the opportunity to cut costs. Come up with new ideas to boost your shipment performance with an advanced performance analytics dashboard, compare your insights to industry standards, and optimize your operations.",
        },
        {
            header: "Admin Panel",
            text: "Take full control of your e-commerce business with an intuitive and robust admin panel. Transform your daily operations by creating a centralized dashboard to manage all aspects of the business from a user-friendly environment and get instant access to crucial metrics and reports to quickly and efficiently make decisions. Automate workflows of order processing, returns, and exchanges to reduce manual errors and save time. Easily connect with existing e-commerce platforms, payment gateways, and other third-party services for a cohesive and streamlined operation.",
        },
        {
            header: "Store Management",
            text: "Optimize and streamline your online store with our comprehensive e-commerce solutions. Enhance user experience with intuitive design and seamless navigation. Manage inventory effortlessly and process orders efficiently with our integrated tools. Boost revenue with automatic sales and discount features tailored to your business needs. Ensure a smooth and secure checkout process with our robust payment gateway solutions.",
        },
        {
            header: "Fraud Detection and Data Protection",
            text: "Ensure the security and privacy of your business with our advanced solutions. Detect and prevent fraudulent activities in real-time, safeguarding both your finances and customer information. Implement Two-Factor Authentication to enhance account security with an extra layer of protection against unauthorized access. Comply with stringent Payment Card Industry (PCI) standards to maintain data security across all transactions.",
        },
        {
            header: "Interactivity and Gamification ",
            text: "Step into the future of e-commerce by implementing interactive and gamification features. Build in quizzes, polls, and personalized recommendations to make shopping fun and more personalized, engage users with challenges and competitions, and set up a loyalty program that would reward customers for purchases, referrals, and social shares. Catch the buyers interest by visualizing the product in their own environment with virtual try-ons and Augmented Reality. ",
        },
        {
            header: "Looking for a solution tailored to your business needs?",
            text: "Fill out the form to get in touch, and we'll reach out to answer all your questions!",
            link: NAVIGATION_LINKS.CONTACT_US
        },
    ]
}

export const E_COMMERCE_SERVICES = [
    {
        header: "Mobile App Development",
        desc: "Provide a smooth shopping experience on the go with mobile applications for Android and iOS.",
        icon: <MobileDevelopmentIcon width="60px" height="60px" viewBox="0 0 60 60"/>
    },
    {
        header: "Web Development",
        desc: "A responsive and visually appealing e-commerce website tailored to your brand and business needs.",
        icon: <WebDevelopmentIcon width="60px" height="60px" viewBox="0 0 60 60"/>
    },
    {
        header: "Data Analytics",
        desc: "Track customer behavior, sales trends, and marketing effectiveness, providing actionable insights with the help of analytics tools.",
        icon: <DataAnalyticsIcon width="60px" height="60px" viewBox="0 0 60 60"/>
    },
    {
        header: "Cybersecurity",
        desc: "Protect your e-commerce platform with robust cybersecurity measures, including SSL certificates, encryption, and regular security audit.",
        icon: <CybersecurityIcon width="60px" height="60px" viewBox="0 0 60 60"/>
    },
    {
        header: "API Integration",
        desc: "Integrate third-party APIs for enhanced functionality, such as shipping carriers, payment processors, and marketing tools.",
        icon: <ApiIntegrationIcon width="60px" height="60px" viewBox="0 0 60 60"/>
    },
    {
        header: "AI and Machine Learning",
        desc: "Use AI and machine learning to offer personalized product recommendations, chatbots for customer service, and predictive analytics.",
        icon: <MachineLearningIcon width="60px" height="60px" viewBox="0 0 60 60"/>
    },
]

export const E_COMMERCE_WORKFLOW = [
    {
        number: "01",
        header: "Consultation",
        text: "We set up meetings to identify your business needs, goals, and project requirements.",
    },
    {
        number: "02",
        header: "Planning",
        text: "We develop a detailed project plan to ensure a clear roadmap.",
    },
    {
        number: "03",
        header: "Design",
        text: "Our team creates user-friendly and visually appealing designs according to your objectives and brand.",
    },
    {
        number: "04",
        header: "Development & Testing",
        text: "Our team develops the software using best practices and carries out rigorous testing to ensure robust functionality and performance.",
    },
    {
        number: "05",
        header: "Deployment",
        text: "Our team launches your software and provides ongoing support to ensure error-free operation and constant improvement.",
    },
]

export const E_COMMERCE_CLIENTS = [
    "Packsmith", "Bazo", "TryNow"
]