import React from "react"
import "./index.scss"

const TechnologiesListItem = ({
    logo, 
    style,
    title,
}) => {
    return (
        <div className={"technologies-list-icons-container"}>
            <div className="technologies-list-icons-container-title t-l-12">{title}</div>
            <img
                src={logo}
                alt={title}
                style={style}
                loading="lazy"
            ></img>
        </div>
    )
}

export default TechnologiesListItem
