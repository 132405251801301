import React from "react"
import LazyImage from "../../components/LazyImage"
import "./index.scss"

export const CaseHeader = ({header, desc, image, industry, duration, location, service }) => {
    return (
        <div className="case-template-header">
            <div className="case-template-header-image">
                <LazyImage src={image} alt={`${header} & Newsoft`} />
            </div>
            <div className="case-template-header-content">
                <h1 className="t-h-m-16">{header}</h1>
                <h2 className="t-h-m-22">{desc}</h2>
                <div className="case-template-header-content-extra">
                    <div className="case-template-header-content-extra-industry">
                        <div className="case-template-header-content-extra-text">
                            <h5 className="t-l-12">Industry</h5>
                            <p className="t-s-14">{industry.label}</p>
                        </div>
                        <hr/>
                    </div>
                    <div className="case-template-header-content-extra-duration">
                        <div className="case-template-header-content-extra-text">
                            <h5 className="t-l-12">Duration</h5>
                            <p className="t-s-14">{duration}</p>
                        </div>
                        <hr/>
                    </div>
                    <div className="case-template-header-content-extra-location">
                        <div className="case-template-header-content-extra-text">
                            <h5 className="t-l-12">Location</h5>
                            <p className="t-s-14">{location}</p>
                        </div>
                        <hr/>
                    </div>
                    <div className="case-template-header-content-extra-service">
                        <h5 className="t-l-12">Service</h5>
                        <div className="case-template-header-content-extra-service-list">
                            {service.map((el, id) => (
                                <p className="t-s-14" key={id}>{el.label}</p>)
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
